import {Form, Select} from "antd";
import {useMemo, useState} from "react";

export default function Check({parameters}) {
    const [selectedValue, setSelectedValue] = useState([]);
    const available = useMemo(() =>
            parameters.properties.map(({name}) => name)
                ?.filter(item => !selectedValue.includes(item))
                ?.map(item => ({label: item, value: item})),
        [parameters, selectedValue])

    return (
        <>
            <Form.Item label={'Horário de início'}
                       rules={[
                           {
                               required: true,
                               message: "É preciso escolher um parametro do gatilho",
                           }
                       ]}
                       name={['action', 'data', 'start']}>
                <Select placeholder={'Início'}
                        options={available}
                        onChange={(value) => setSelectedValue(value)}/>
            </Form.Item>
            <Form.Item label={'Horário de encerramento'}
                       rules={[
                           {
                               required: true,
                               message: "É preciso escolher um parametro do gatilho",
                           }
                       ]}
                       name={['action', 'data', 'end']}>
                <Select placeholder={'Fim'}
                        options={available}
                        onChange={(value) => setSelectedValue(value)}/>
            </Form.Item>
        </>
    )
}
