import Fetch from "."

export const getTasks = async (assistantId) => {
    return Fetch(`/assistant/${assistantId}/task`);
}

export const getTask = async (assistantId, id) => {
    return Fetch(`/assistant/${assistantId}/task/${id}`);
}

export const createTask = async (assistantId, body) => {
    return Fetch(
        `/assistant/${assistantId}/task/`,
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            }
        },
    );
}

export const updateTask = async (assistantId, id, body) => {
    return Fetch(
        `/assistant/${assistantId}/task/${id}`,
        {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            }
        },
    );
}


export const deleteTask = async (assistantId, id) => {
    if (!id || !assistantId)
        return Promise.reject("empty id sent");

    return Fetch(
        `/assistant/${assistantId}/task/${id}`,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    );
}



