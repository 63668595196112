import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, List, Popconfirm, Tag } from "antd";
import { Routes, Route, Link } from "react-router-dom";
import { getAssistant } from "../../services/assistant"
import { Header, Title } from "../users/styles";
import { DeleteOutlined } from "@ant-design/icons";
import { formatDateTime, formatPhoneNumber } from "../../utils";
import { deleteMyZapInstance, getMyZapInstances } from "../../services/myZap";
import AdminLayout from "../../components/admin-layout";
import MessageApiContext from "../../context/message-api";
import EditMyZap from "./Edit";

export default function MyZap() {
    return (
        <AdminLayout>
            <Routes>
                <Route index path={'/'} element={<MyZapList />} />
                <Route index path={'/:id'} element={<EditMyZap />} />
            </Routes>
        </AdminLayout>
    )
}

function MyZapList() {
    const messageApi = useContext(MessageApiContext);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const [instances, setInstances] = useState([]);

    const reload = useCallback(async () => {
        setLoading(true);
        const { data } = await getMyZapInstances();
        setInstances(data);
        setTotal(data.length);
        setLoading(false);
    }, [setInstances, setTotal, setLoading]);

    useEffect(() => {
        reload().catch(() => messageApi.error("Falha ao carregar usuários"))
    }, [page, perPage, messageApi, reload])

    return (
        <>
            <List
                orientation={'left'}
                itemLayout={'horizontal'}
                dataSource={instances}
                loading={loading}
                header={
                    <Header>
                        <Title>Instâncias MyZap</Title>
                    </Header>
                }
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page,
                    showSizeChanger: true,
                    pageSizeOptions: [25, 50, 100]
                }}
                renderItem={(item) => <InstanceContainer reload={reload} data={item} />}
            />
        </>
    );
}


function InstanceContainer({ data, reload }) {
    const [name, setName] = useState('');
    const [color, setColor] = useState('purple');
    const [status] = useState(data.status?.status);
    const [phoneNumber, setPhoneNumber] = useState('');

    const getStatus = () => {
        return status ? (status === 'notLogged' ? "Desconectado" : status) : "Inativo";
    }

    const getConnectedSince = () => {
        if (!data.status?.last_connect) return "Data indisponível"
        return formatDateTime(data.status?.last_connect);
    }

    const getPhoneNumber = useCallback((data) => {
        if (!data.status?.number) return setPhoneNumber("Número não conectado");
        const formatedNumber = formatPhoneNumber(data.status?.number.split('@')[0]);
        return setPhoneNumber(formatedNumber);
    }, []);

    useEffect(() => {
        if (!data) return;
        getPhoneNumber(data);
    }, [data, getPhoneNumber]);

    useEffect(() => {
        if (!status) setColor('red');
        if (status === "notLogged") setColor('red');
        if (status === "qrCode") setColor('yellow');
        if (status === "connected") setColor('green');
    }, [status]);

    useEffect(() => {
        getAssistant(data.assistantID).then(({ data }) => {
            setName(data.name);
        }).catch((e) => console.log(e))
    }, [data.assistantID]);

    return (
        <List.Item
            actions={[
                <Tag color={color}>
                    {getStatus()}
                </Tag>,
                <Link to={`${data?.id}`}>
                    <Button type={'text'}>Editar</Button>
                </Link>,
                <Popconfirm
                    title="Você tem certeza?"
                    description="Essa ação vai deletar a instância"
                    onConfirm={async () => {
                        await deleteMyZapInstance(data?.id);
                        await reload().catch(console.error);
                    }}
                    cancelText={'Cancelar'}>
                    <Button danger type={'text'} style={{ marginLeft: '1rem' }} icon={<DeleteOutlined />} />
                </Popconfirm>
            ]}
        >
            <List.Item.Meta
                title={<Link to={`/assistants/${data.assistantID}`}>{name}</Link>}
                description={
                    `${getConnectedSince()} | ${phoneNumber}`
                }
            />
        </List.Item>
    )
}
