import {Form, Select} from "antd";
import {useMemo} from "react";
import SetLabel from "./SetLabel";
import SendFile from "./SendFile";
import SendImage from "./SendImage";

const actions = [
    {
        label: "Atribuir etiqueta",
        value: "SET_TAG",
    },
    {
        label: "Resetar sessão (histórico)",
        value: "RESET_SESSION",
    },
    {
        label: "Enviar Arquivo",
        value: "SEND_FILE",
    },
    {
        label: "Enviar Imagem",
        value: "SEND_IMAGE",
    },
    {
        label: "Marcar como não lido",
        value: "MARK_UNREAD",
    }
]

export default function EvolutionForm({form, extraData}) {
    const type = Form.useWatch(['action', 'type'], form);

    const instancesOptions = useMemo(() => extraData && extraData.map(({uuid, active}) => ({
        label: `${uuid} - (${active ? 'Ativo' : 'Inativo'})`,
        value: uuid
    })), [extraData])

    return (
        <>
            <Form.Item label={'Instancia Evolution'} name={['action', 'instanceID']}>
                <Select options={instancesOptions} placeholder={"Selecione a instancia"}/>
            </Form.Item>
            <Form.Item label={'Tipo de ação'} name={['action', 'type']}>
                <Select options={actions} placeholder={"Selecione o tipo de ação"}/>
            </Form.Item>
            {type === 'SET_TAG' && <SetLabel/>}
            {type === 'SEND_FILE' && <SendFile/>}
            {type === 'SEND_IMAGE' && <SendImage/>}
        </>
    )
}
