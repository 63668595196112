import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import ActionsContext from "../../context/actions";
import ChatBotDataContext from "../../context/chatbot-data";
import ConditionMultiSelector from "../condition-multi-selector";
import ContextActionInput, {SetText} from "../context-action-input";
import {Locker} from "../locker";
import {
    Button, Checkbox,
    Col,
    Collapse,
    Form,
    Input, InputNumber,
    Popover,
    Radio,
    Row,
    Select,
    Space, Switch,
    theme,
    Typography
} from "antd";
import {
    BulbOutlined,
    CloudOutlined,
    EditOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {WebhookModal} from "../node";
import {newObjectID} from "../../utils";
import {Response} from "../response";
import {CardButton, Container} from "./styles";
import {InputSearch} from "../input-search";
import {getChatBots} from "../../services/chatbot";
import {GenerativeChatbot} from "../../constants";
import {Link} from "react-router-dom";
import {useEdgesState, useReactFlow} from "reactflow";

const tabs = [{
    label: 'Basico', value: 'basic'
}, {
    label: 'Contexto', value: 'context'
}, {
    label: 'Integrações', value: 'integrations'
}]

export default function NodeEditor({setNodeData, nodeData}) {
    const {setEdges} = useReactFlow();
    const [data, setData] = useContext(ChatBotDataContext);
    const editing = useMemo(() => nodeData?.id, [nodeData?.id]);
    const responsesData = useMemo(() => Object.values(nodeData?.responses ?? {}).map((id) => data?.responses && data?.responses[id] ? data?.responses[id] : null).filter(item => item), [data?.responses, nodeData])
    const [isIframe] = useContext(ActionsContext)['iframe'];
    const [responses, setResponses] = useState([]);
    const [modalOpen, setModalOpen] = useState(-1);
    const [tab, setTab] = useState('basic');
    const [isNew, setIsNew] = useState(false);

    const {
        token: {colorPrimaryBorderHover, colorPrimaryTextHover, colorBgElevated, borderRadius, boxShadow},
    } = theme.useToken();

    const handleFieldChange = useCallback((field) => ({target: {value}}) => setData(old => {
        setNodeData(old => ({...old, [field]: value}));
        old.nodes[editing][field] = value;
        return {...old};
    }), [nodeData, editing]);

    useEffect(() => {
        if (!nodeData) return;

        const resData = responsesData ?? [{type: 'text'}];
        for (let i in resData)
            resData[i].nodeId = editing;

        setResponses(resData);
    }, [editing])

    useEffect(() => {
        if (responses?.length <= 0) return;

        setData(old => {
            if (!old.nodes) old.nodes = {};
            if (!old.nodes[editing]) old.nodes[editing] = {};

            for (const value of responses) {
                if (!value?.id) value.id = newObjectID()
                old.responses = {...old?.responses, [value.id]: value}
            }

            old.nodes[editing].responses = responses.map(item => item.id);
            setNodeData(old => ({...old, responses: responses.map(item => item.id)}));
            return {...old}
        });
    }, [responses, editing])

    const addContextAction = useCallback(() => {
        setNodeData(old => {
            old.contextActions = [...(old.contextActions || []), {type: SetText}];
            return {...old};
        })
        setData(old => {
            if (!old.nodes) old.nodes = {};
            if (!old.nodes[nodeData?.id]) old.nodes[nodeData?.id] = {}

            old.nodes[nodeData?.id].contextActions = [...(old.nodes[nodeData?.id].contextActions || []), {type: SetText}];
            return {...old};
        })
    }, [nodeData?.id]);

    const removeContextAction = useCallback((i) => {
        let newData = {...data};
        
        if (!newData.nodes || !newData.nodes[nodeData?.id] || !newData.nodes[nodeData?.id].contextActions)
            return;
    
        let updatedContextActions = [...newData.nodes[nodeData?.id].contextActions];
        updatedContextActions.splice(i, 1);
    
        newData.nodes[nodeData?.id].contextActions = updatedContextActions;
    
        setNodeData(old => ({
            ...old,
            contextActions: old.contextActions.filter((_, index) => index !== i)
        }));
    
        setData(newData);
    }, [nodeData?.id, data]);

    const deleteWebhook = useCallback((i) => {
        setNodeData(old => {
            const actions = [...old.actions];
            actions.splice(i, 1);

            return {...old, actions};
        })
        setData(old => {
            const actions = [...old.nodes[editing]?.actions];
            actions.splice(i, 1);

            return {
                ...old, nodes: {
                    ...old.nodes, [editing]: {
                        ...old.nodes[editing], actions
                    }
                }
            };
        })
    }, [editing, setData])


    const httpActions = useMemo(() => !nodeData || !nodeData?.actions || nodeData?.actions?.length <= 0 ? [] : nodeData?.actions?.filter(({type}) => type === 'HTTP_REQUEST') || [], [nodeData?.actions]);
    const generativeCalls = useMemo(() => !nodeData || !nodeData?.actions ? [] : nodeData?.actions?.filter(({type}) => type === 'GENERATIVE_CALL') || [], [nodeData?.actions]);

    const addWebhook = useCallback(() => {
        setNodeData(old => {
            old.actions = [...(old.actions || []), {
                type: 'HTTP_REQUEST', data: {url: '', method: 'GET', body: ''}
            }];
            return {...old};
        })
        setData(old => {
            if (!old.nodes) old.nodes = {};
            if (!old.nodes[editing]) old.nodes[editing] = {};

            setModalOpen(old.nodes[editing].actions?.length || 0);
            setIsNew(true);
            old.nodes[editing].actions = [...(old.nodes[editing].actions || []), {
                type: 'HTTP_REQUEST', data: {url: '', method: 'GET', body: ''}
            }];
            return {...old};
        })
    }, [editing])

    const handleTypeChange = useCallback((oldType, newType, responseData) => {
        if (oldType === "text" && oldType !== newType) {

            return [{text: responseData}]
        }
        if (newType === "text" && oldType !== newType) {
            return responseData[0].text
        }
        return responseData
    }, [responses])
    // const addGenerative = useCallback(() => {
    //     let defaultGenerative = {
    //         chatbotId: 0,
    //         addictionalContext: '',
    //         isLoop: false,
    //         interactionLimit: 3,
    //         exitCondition: ''
    //     };

    //     setNodeData(old => {
    //         old.actions = [...(old.actions || []), {
    //             type: 'GENERATIVE_CALL', data: defaultGenerative
    //         }];
    //         return {...old};
    //     })

    //     setData(old => {
    //         old.nodes[editing].actions = [...(old.nodes[editing].actions || []), {
    //             type: 'GENERATIVE_CALL', data: defaultGenerative
    //         }];
    //         return {...old};
    //     })
    // }, [setData, editing])

    const handleIgnore = useCallback(({target: {checked}}) => {
        handleFieldChange('skipUserInput')({target: {value: checked}})
        setEdges(old => {
            return [
                ...old.map(edge =>
                    edge.source === editing ? {...edge, animated: checked} : edge
                )
            ]
        });
    }, [editing])

    return (
        <Container className={'nodrag nowheel'} bg={colorBgElevated} borderRadius={borderRadius} boxShadow={boxShadow}>
            {modalOpen !== -1 && <WebhookModal actionIndex={modalOpen} nodeId={editing}
                                               isNew={isNew}
                                               setNodeData={setNodeData}
                                               deleteWebhook={deleteWebhook}
                                               action={modalOpen !== -1 && httpActions[modalOpen]?.data}
                                               open={modalOpen !== -1}
                                               setOpen={(isOpen) => {
                                                   setModalOpen(old => isOpen ? old : -1);
                                                   setIsNew(false);
                                               }}/>}

            <Form layout={'vertical'}>
                <Row justify={'space-between'}>
                    <Col span={20}>
                        <Form.Item label={'Título'}>
                            <Input
                                className={'nodrag nopan'}
                                value={nodeData?.title}
                                onChange={handleFieldChange('title')}
                                disabled={(isIframe && nodeData?.lock?.title)}
                                placeholder={'Título do node'}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Popover placement={'top'} content={
                            <Space direction={'vertical'}>
                                <Checkbox title={'Ignorar mensagem'}
                                          checked={nodeData?.skipUserInput}
                                          onChange={handleIgnore}>
                                    Ignorar mensagem
                                </Checkbox>
                                <Checkbox title={'Filhos por prioridade'}
                                          checked={nodeData?.selectChildrenByPriority}
                                          onChange={({target: {checked}}) => handleFieldChange('selectChildrenByPriority')({target: {value: checked}})}>
                                    Filhos por prioridade
                                </Checkbox>
                            </Space>
                        }>
                            <Button icon={<SettingOutlined/>} type={'text'}/>
                        </Popover>
                    </Col>
                </Row>
                <Form.Item>
                    <Radio.Group
                        value={tab}
                        onChange={({target: {value}}) => setTab(value)}
                        options={tabs}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </Form.Item>
            </Form>

            {tab === 'basic' && <>
                <Form layout={'vertical'}>
                    <Form.Item
                        label={<>
                            <p>Condições</p>
                            <Locker id={nodeData?.id} param={'condition'}/>
                        </>}
                        tooltip={'Você pode escolher multiplas condições, apenas se a operação acima for cumprida o fluxo passará por este nó.'}>
                        <ConditionMultiSelector node={nodeData} setNData={setNodeData}/>
                    </Form.Item>
                    <Space direction="vertical" size="small" style={{display: 'flex'}}>
                        <Form.Item tooltip={'Lista de respostas'}>
                            {responses.map((item, i) => <React.Fragment key={i}>
                                <Row justify={'space-between'} align={'center'}>
                                    <Col span={responses.length > 1 ? 22 : 24}>
                                        <Row justify={'space-between'} align={'center'}>
                                            <Typography.Text>Resposta</Typography.Text>
                                            <Col>
                                                <Form.Item
                                                    noStyle
                                                    tooltip={'As respostas serão entregues de maneira randômica, qualquer uma das respostas acima podem ser enviadas ao usuário.'}>
                                                    <Select
                                                        bordered={false}
                                                        className="nodrag nopan"
                                                        zIndexPopup={9999}
                                                        defaultValue={'text'}
                                                        disabled={(isIframe && nodeData?.lock?.response) || generativeCalls?.length > 0}
                                                        value={item.type}
                                                        onChange={(value) => setResponses(old => {
                                                            old[i].data = handleTypeChange(old[i].type, value, old[i].data)
                                                            old[i].type = value

                                                            return [...old];
                                                        })}
                                                        style={{minWidth: 90}}
                                                    >
                                                        <Select.Option value={'text'}>Unica</Select.Option>
                                                        <Select.Option value={'multiple'}>Multipla</Select.Option>
                                                        <Select.Option value={'options'}>Opções</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Response isLocked={nodeData?.lock?.response}
                                                  className={'nopan nodrag nowheel nofocus'}
                                                  {...item}
                                                  disabled={generativeCalls?.length > 0}
                                                  add={() => setResponses(old => {
                                                      old[i].data = [...old[i].data, {text: ''}]
                                                      return [...old];
                                                  })}
                                                  remove={(resIndex) => setResponses(old => {
                                                      old[i].data.splice(resIndex, 1)
                                                      return [...old];
                                                  })}
                                                  onChange={(v, resIndex) => setResponses(old => {
                                                      if (resIndex || resIndex === 0) {
                                                          if (!(old[i].data instanceof Array)) old[i].data = [{text: v}]; else old[i].data[resIndex] = {text: v};
                                                      } else old[i].data = v;

                                                      return [...old];
                                                  })}
                                                  isIframe={isIframe}/>
                                    </Col>
                                    {responses?.length > 1 && <Col span={1}>
                                        {responses.length > 1 ? (<MinusCircleOutlined
                                            style={{marginTop: '.5rem'}}
                                            className="dynamic-delete-button"
                                            onClick={() => setResponses(old => {
                                                old.splice(i, 1)
                                                return [...old];
                                            })}
                                        />) : null}
                                    </Col>}
                                </Row>
                            </React.Fragment>)}
                        </Form.Item>
                        <Form.Item noStyle>
                            <Button
                                icon={<PlusOutlined/>}
                                type="dashed"
                                disabled={(isIframe && nodeData?.lock?.response)}
                                onClick={() => setResponses(old => [...old, {
                                    type: 'text', data: "", nodeId: editing
                                }])}>Adicionar Resposta</Button>
                        </Form.Item>
                    </Space>
                </Form>
            </>}

            {tab === 'context' && <>
                <Form layout={'vertical'}>
                    <Form.Item
                        style={{marginBottom: 0}}
                        tooltip={'O contexto pode ser manipulado de forma a ser utilizado em outras partes do fluxo aqui você pode definir um valor para uma chave baseado em critérios específicos.'}
                        label={<>
                            <p>Ações de contexto</p>
                            <Locker id={editing} param={'contextActions'}/>
                        </>}>
                        {nodeData?.contextActions?.map((item, i) => <ContextActionInput
                            setNodeData={setNodeData}
                            disabled={(isIframe && nodeData?.lock?.contextActions)}
                            remove={() => removeContextAction(i)} nodeId={editing} data={item}
                            index={i}
                            key={i}/>)}
                        <Button type={'dashed'}
                                icon={<PlusOutlined/>}
                                style={{marginBottom: 8}}
                                disabled={(isIframe && nodeData?.lock?.contextActions)}
                                onClick={addContextAction}>
                            Adicionar
                        </Button>
                    </Form.Item>
                </Form>
            </>}
            {tab === 'integrations' && <>
                <Row gutter={[12, 12]}>
                    {/* <Col>
                        <CardButton onClick={addGenerative}
                                    disabled={generativeCalls?.length > 0}
                                    hoverColor={colorPrimaryBorderHover}
                                    textHoverColor={colorPrimaryTextHover}>
                            <Space align={'center'} direction={'vertical'} size={1}>
                                <BulbOutlined style={{fontSize: 24}}/>
                                Generativo
                            </Space>
                        </CardButton>
                    </Col> */}
                    <Col>
                        <CardButton hoverColor={colorPrimaryBorderHover}
                                    textHoverColor={colorPrimaryTextHover}
                                    onClick={addWebhook}
                        >
                            <Space align={'center'} direction={'vertical'} size={1}>
                                <CloudOutlined style={{fontSize: 24}}/>
                                Webhook
                            </Space>
                        </CardButton>
                    </Col>
                </Row>
                <br/>
                <Collapse>
                    {httpActions?.length > 0 && <Collapse.Panel key={'webhook'} header={"Webhook"}>
                        {httpActions.map((item, i) => <Row style={{width: '100%'}} gutter={[8, 8]}
                                                           align={'center'}>
                            <Col span={22}>
                                <Form.Item>
                                    <Button style={{width: '100%'}} icon={<EditOutlined/>}
                                            onClick={() => setModalOpen(i)}>
                                        {httpActions?.length > 0 && httpActions[i]?.data?.url?.substring(0, 25) + (httpActions[i]?.data?.url?.length > 25 ? '...' : '') || "Editar"}
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Button danger type={'text'} icon={<MinusCircleOutlined/>}
                                        onClick={() => deleteWebhook(i)}
                                />
                            </Col>
                        </Row>)}
                        <Button
                            type="dashed"
                            onClick={addWebhook}
                            icon={<PlusOutlined/>}
                        >
                            Adicionar Webhook
                        </Button>
                    </Collapse.Panel>}
                    {generativeCalls?.length > 0 && <Collapse.Panel key={'generative'} header={"Generativo"}>
                        <Form layout={'vertical'}>
                            <Form.Item label={'Assistente generativo'}>
                                <InputSearch
                                    getCallback={(rest) => getChatBots({type: GenerativeChatbot, ...rest})}
                                    notFoundContent={
                                        <Link to={'/chatbots/create'}>
                                            <Button style={{width: '100%'}} icon={<PlusOutlined/>}>
                                                Criar assistente generativo
                                            </Button>
                                        </Link>
                                    }
                                />
                            </Form.Item>
                            <Form.Item label={'Contexto adicional'}>
                                <Input.TextArea placeholder={'O usuário está com dúvida em relação aos boletos'}
                                                autoSize/>
                            </Form.Item>
                            <Form.Item label={'Loop'}
                                       tooltip={'O assistente não vai sair do node até que a condição de saída seja satisfeita.'}>
                                <Switch/>
                            </Form.Item>
                            <Form.Item label={'Limite de interações'}>
                                <InputNumber defaultValue={1} min={1}/>
                            </Form.Item>
                            <Form.Item label={'Condição de saída'}>
                                <Input.TextArea autoSize
                                                placeholder={'Quando o cliente pedir algo fora do escopo de boleto'}/>
                            </Form.Item>
                        </Form>
                    </Collapse.Panel>}
                </Collapse>
            </>}
        </Container>)
}
