import {Form, Select} from "antd";
import Check from "./Check";
import Schedule from "./Schedule";
import List from "./List";

const actions = [
    {
        label: "Checar Disponibilidade",
        value: "CHECK",
    },
    {
        label: "Marcar Horário",
        value: "SCHEDULE",
    },
    {
        label: "Horários disponíveis",
        value: "LIST",
    }
]

export default function GoogleCalendarForm({form, parameters}) {
    const type = Form.useWatch(['action', 'type'], form);

    return (
        <>
            <Form.Item label={'Tipo de ação'} name={['action', 'type']}>
                <Select options={actions} placeholder={"Selecione o tipo de ação"}/>
            </Form.Item>
            {type === 'CHECK' && <Check parameters={parameters}/>}
            {type === 'SCHEDULE' && <Schedule parameters={parameters}/>}
            {type === 'LIST' && <List parameters={parameters}/>}
        </>
    )
}
