import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Divider, Form } from 'antd';
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { getZAPIInstanceById, updateZAPIInstance } from '../../../services/zAPIInstances';
import { getAssistants } from '../../../services/assistant';
import { InputSearch } from '../../../components/input-search';
import MessageApiContext from '../../../context/message-api';

export default function EditZApi() {
    const navigate = useNavigate();
    const messageApi = useContext(MessageApiContext);
    const { id } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const extraDataRef = useRef();

    useEffect(() => {
        if (!id)
            return;

        setLoading(true);
        getZAPIInstanceById(id).then(({ data }) => {
            extraDataRef.current = data;
            form.setFieldsValue(data);
            setLoading(false);
        }).catch((e) => {
            console.error(e);
            messageApi.error("Falha ao carregar informações da instância");
        })
    }, [id, form, messageApi])

    const save = useCallback((values) => {
        setSaving(true);
        const updatedValues = {
            ...extraDataRef.current,
            ...values
        }
        updateZAPIInstance(id, updatedValues).then(() => {
            setSaving(false);
            navigate('/zapi');
            messageApi.success("Instância atualizada com sucesso!")
        }).catch(e => {
            console.error(e);
            setSaving(false);
            messageApi.error("Falha ao atualizar instância");
        })
    }, [id, navigate, setSaving, messageApi])

    return (
        <>
            <Form
                style={{
                    maxWidth: 600,
                }}
                disabled={loading}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={save}
            >
                <Form.Item
                    name="assistantID"
                    label={'Assistente'}
                    rules={
                        [
                            {
                                required: true,
                                message: 'Você deve selecionar o assistente'
                            }
                        ]
                    }
                >
                    <InputSearch getCallback={getAssistants} />
                </Form.Item>
                <Divider />
                <Form.Item>
                    <Button loading={saving} icon={<SaveOutlined />} type="primary" htmlType="submit">
                        Salvar
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
