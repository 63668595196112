import {
    CloseOutlined, DeleteOutlined,
    DownloadOutlined, PlusOutlined, UserOutlined,
} from "@ant-design/icons";
import {useGoogleLogin} from "@react-oauth/google";
import {Button, Card, Col, Row, Typography} from "antd";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    createCalendarIntegration, deleteCalendarIntegration,
    getCalendarIntegration,
} from "../../services/calendar-integration";
import MessageApiContext from "../../context/message-api";

export default function CalendarIntegration({icon, label, assistantID}) {
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonSpecificLoading, setButtonSpecificLoading] = useState(false);
    const messageApi = useContext(MessageApiContext);
    const [data, setData] = useState([]);

    const login = useGoogleLogin({
        onSuccess: (response) => {
            save(response?.access_token)
            setButtonLoading(false);
        },
        onNonOAuthError: (nonOAuthError) => {
            if (nonOAuthError.type === 'popup_closed')
                messageApi.info("Janela de autenticação fechada, cancelado pelo usuário.");
            if (nonOAuthError.type === 'popup_failed_to_open')
                messageApi.warn("Falha ao abrir popup, verifique a configurações de popup do seu navegador.");

            setButtonLoading(false);
        },
        onError: (error) => {
            console.error("Erro:", {error});
            messageApi.error("Erro ao instalar google agenda, tente novamente.");
            setButtonLoading(false);
        },
        scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    })

    const handleInstall = useCallback(() => {
        setButtonLoading(true);
        login();
    }, [login])

    const handleUninstall = useCallback((id) => {
        setButtonSpecificLoading(id);
        deleteCalendarIntegration(id)
            .then(() => {
                setData(old => old.filter((item) => item.id !== id));
            })
            .catch(() => {
                messageApi.error("Erro ao deletar integração com google.");
            })
            .finally(() => {
                setButtonSpecificLoading(null);
            });
    }, [login])

    useEffect(() => {
        setLoading(true);
        getCalendarIntegration({assistantID})
            .then(({data}) => {
                setData(data);
            })
            .catch(() => {
                messageApi.error("Erro ao carregar integrações do Calendar.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [assistantID]);

    const save = useCallback((token) => {
        setButtonLoading(true);

        createCalendarIntegration({token, assistantID: Number(assistantID), active: true})
            .then(({data}) => {
                messageApi.success("Google agenda instalado com sucesso!");
                setData(old => [...old, data]);
            })
            .catch(() => {
                messageApi.error("Erro ao salvar as integrações com google agenda");
            })
            .finally(() => {
                setButtonLoading(false);
            });
    }, [assistantID, createCalendarIntegration, setButtonLoading]);

    const hasData = useMemo(() => data?.length > 0, [data])

    return (
        <>
            <Card
                loading={loading}
                title={
                    <Typography.Title level={5} ellipsis={{tooltip: label}}>
                        {icon} {label}
                    </Typography.Title>
                }
                extra={
                    <Button type={hasData ? 'default' : 'primary'}
                            loading={loading || buttonLoading}
                            danger={hasData}
                            disabled={data?.length > 1}
                            onClick={() => hasData ? handleUninstall() : handleInstall()}
                            icon={hasData ? <CloseOutlined/> : <DownloadOutlined/>}
                    >{loading ? undefined : hasData ? 'Remover' : 'Instalar'}</Button>
                }
            >
                {hasData ?
                    <Row gutter={[8, 8]}>
                        {data.map((item, i) => <Col>
                                <Button danger
                                        loading={buttonSpecificLoading === item.id}
                                        onClick={() => handleUninstall(item.id)}
                                        icon={<DeleteOutlined/>}>Conta {i + 1}</Button>
                            </Col>
                        )}

                        <Col>
                            <Button onClick={handleInstall}
                                    loading={buttonLoading}
                                    icon={<PlusOutlined/>}>Adicionar</Button>
                        </Col>
                    </Row> :
                    <Typography.Paragraph>Configure o google agenda.</Typography.Paragraph>
                }
            </Card>
        </>
    );
}
