import React, {useContext, useEffect, useMemo, useState} from "react";
import {deleteChatbot, getChatBots, updateChatBot} from "../../services/chatbot";
import {Button, Input, List, Row, Space, Tag, Typography,} from "antd";
import {Link, Routes, Route} from "react-router-dom";
import Versions from "../versions";
import ChatBotFlowEdit from "../chatbot-flow-edit";
import AdminLayout from "../../components/admin-layout";
import SelectFunction from "../select-function";
import "./style.css";
import ChatBotCreate from "../chatbot-create";
import {AdminRole} from "../../constants";
import UserContext from "../../context/users";
import ChatbotCard from "../../components/chatbot-card";
import MessageApiContext from "../../context/message-api";
import CompanyFilters from "../../components/company-filter";

export default function ChatBots() {
    return (
        <Routes>
            <Route path={'/:id/versions/editor/:snapshot/*'} element={<SelectFunction/>}/>
            <Route path={'*'} element={
                <AdminLayout>
                    <Routes>
                        <Route index path={'/'} element={<ChatBotIndex/>}/>
                        <Route path={'/create'} element={<ChatBotCreate/>}/>
                        <Route path={'/:id'} element={<ChatBotFlowEdit/>}/>
                        <Route path={'/:id/versions/*'} element={<Versions/>}/>
                    </Routes>
                </AdminLayout>
            }/>
        </Routes>
    )
}

function ChatBotIndex() {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [chatbots, setChatBots] = useState([]);
    const messageApi = useContext(MessageApiContext);
    const [filters, setFilters] = useState({});
    const [user] = useContext(UserContext);
    const [search, setSearch] = useState('');

    useEffect(() => {
        reloadList()
    }, [page, perPage, filters])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPage(1);
            reloadList();
        }, 400);

        return () => clearTimeout(timeout);
    }, [search])

    const reloadList = () => {
        setLoading(true);
        const filter = {search, searchField: 'name'};
        for (let key in filters)
            filter[key] = filters[key];

        getChatBots({page, perPage, graph: true, ...filter}).then(({data, total}) => {
            setChatBots(data);
            setTotal(total);
        }).catch(console.error).finally(() =>
            setLoading(false)
        )
    }

    const filterArray = useMemo(() => Object.values(filters), [filters]);
    const filterArrayKeys = useMemo(() => Object.keys(filters), [filters]);

    return (
        <List
            itemLayout="horizontal"
            grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 5,
            }}
            className={'antd-list-custom'}
            header={
                <>
                    <Row justify={'space-between'}>
                        <Typography.Title level={3}>Fluxo</Typography.Title>
                        <Space direction={'horizontal'}>
                            {filterArray.map((item, i) => <Tag closable onClose={() => setFilters(old => {
                                delete old[filterArrayKeys[i]];
                                return {...old};
                            })}>Empresa</Tag>)}
                            <Input.Search placeholder={'Pesquisa por nome'}
                                          onSearch={(search) => setSearch(search)}
                                          onChange={({target}) => setSearch(target.value)}/>
                            <Link to={'create'}>
                                <Button type="primary">
                                    Criar
                                </Button>
                            </Link>
                            {user.role === AdminRole && <CompanyFilters onFinish={setFilters} filters={filters}/>}
                        </Space>
                    </Row>
                </>
            }
            loading={loading}
            pagination={{
                position: "bottom",
                align: "end",
                onChange: (page, perPage) => {
                    setPage(page);
                    setPerPage(perPage);
                },
                total,
                pageSize: perPage,
                current: page
            }}
            dataSource={chatbots}
            renderItem={item => (
                <ChatbotCard
                    messageApi={messageApi}
                    setFilters={setFilters}
                    updateCall={updateChatBot}
                    deleteCall={deleteChatbot}
                    reloadList={reloadList}
                    data={item}/>
            )}
        />
    );
}
