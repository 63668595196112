import {Form, Mentions, Select} from "antd";
import {useCallback, useMemo, useState} from "react";


const numFormFields = 4;

export default function Schedule({parameters}) {
    const [selectedValue, setSelectedValue] = useState([]);
    const available = useMemo(() =>
            parameters.properties.map(({name}) => name)
                ?.filter(item => !selectedValue.includes(item))
                ?.map(item => ({label: item, value: item})),
        [parameters, selectedValue])

    const handleValueChange = useCallback((value) =>
            setSelectedValue(old => old?.length < numFormFields ? [...old, value] : old),
        []);

    return (
        <>
            <Form.Item label={'Horário de início'}
                       rules={[
                           {
                               required: true,
                               message: "É preciso escolher um parametro do gatilho",
                           }
                       ]}
                       name={['action', 'data', 'start']}>
                <Select placeholder={'Início'}
                        options={available}
                        onChange={handleValueChange}/>
            </Form.Item>
            <Form.Item label={'Horário de encerramento'}
                       rules={[
                           {
                               required: true,
                               message: "É preciso escolher um parametro do gatilho",
                           }
                       ]}
                       name={['action', 'data', 'end']}>
                <Select placeholder={'Fim'}
                        options={available}
                        onChange={handleValueChange}/>
            </Form.Item>
            <Form.Item label={'ID de integração'}
                       help={"Esse id é retornado nas outras rotas, de check e list."}
                       name={['action', 'data', 'integrationID']}>
                <Select placeholder={'ID de integração'}
                        options={available}
                        onChange={handleValueChange}/>
            </Form.Item>
            <Form.Item label={'Assunto da reunião'}
                       help={"Você pode definir um assunto fixo ou selecionar um parametro digitando $"}
                       name={['action', 'data', 'summary']}>
                <Mentions placeholder={'Assunto'}
                          options={available}
                          prefix={"$"}
                          onChange={handleValueChange}/>
            </Form.Item>
        </>
    )
}
