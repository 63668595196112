import { Link, useParams } from "react-router-dom";
import {
	Button,
	Card,
	Divider,
	List,
	Popover,
	Skeleton,
	Space,
	Tag,
	theme,
	Tooltip,
	Typography,
} from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { getGenerativeSessionHistory } from "../../../../../services/generative-message-logs";
import MessageApiContext from "../../../../../context/message-api";
import { updatedSince, formatDate } from "../../../../../utils";
import {
	ArrowLeftOutlined,
	ThunderboltOutlined,
	WarningOutlined,
	ClockCircleOutlined,
	LinkOutlined,
	DollarOutlined,
} from "@ant-design/icons";
import { getSourceData } from "../../../../../utils/extensionIconFormatter";
import UserContext from "../../../../../context/users";
import { AdminRole } from "../../../../../constants";

export default function View() {
	const { historic_id } = useParams();
	const messageApi = useContext(MessageApiContext);
	const [data, setData] = useState({});
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(6);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(true);
    const [user] = useContext(UserContext);
	const {
		token: { colorPrimary },
	} = theme.useToken();

	useEffect(() => {
		setLoading(true);
		getGenerativeSessionHistory({
			sessionID: historic_id,
			perPage,
			page,
		})
			.then(({ data, total }) => {
				setData(data);
				setTotal(total);
			})
			.catch((error) => {
				console.error(error);
				messageApi.error("Falha ao carregar histórico.");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [perPage, page, historic_id]);

	if (loading) return <Skeleton active />;

	return (
		<List
			loading={loading}
        	pagination={{
        	    position: "bottom", align: "end", onChange: (page, perPage) => {
        	        setPage(page);
        	        setPerPage(perPage);
        	    }, total, pageSize: perPage, current: page,
        	}}>
			<Space direction={"vertical"} style={{ width: "100%" }}>
				<Link to={".."}>
					<Button icon={<ArrowLeftOutlined />} style={{ padding: 2 }} type={"text"}>
						Histórico
					</Button>
				</Link>
				<Typography.Title level={4}>
					{historic_id}
					{user.role === AdminRole && <Tooltip
						title={
						<>
							<b>Provider:</b> {data[0].llmProvider} <br/>
							<b>Modelo utilizado:</b> {data[0].llmModel} <br/>
							<b>Input tokens:</b> {data[0].costs.total.inputTokens} <br/>
							<b>Output tokens:</b> {data[0].costs.total.outputTokens} <br/>
							<b>LLM cost:</b> {data[0].costs.total.llmCost}
						</>}>
						<DollarOutlined style={{ color: colorPrimary, paddingLeft: "10px" }} />
					</Tooltip>}
				</Typography.Title>
				<Typography.Text>
					{updatedSince(new Date(data[0]?.createdAt))} atrás
				</Typography.Text>
				<Space
					direction={"vertical"}
					style={{
						maxHeight: "600px",
						overflowY: "scroll",
						width: "100%",
					}}
				>
					{data.map((item, i) => (
						<Message key={i} {...item} />
					))}
				</Space>
			</Space>
		</List>
	);
}

function Message({
	userMessage,
	response,
	isAnythingElse,
	createdAt,
	responseTime,
    clientData
}) {
	const {
		token: { colorPrimary },
	} = theme.useToken();

	return (
		<div
			style={{
				display: "grid",
				flexDirection: "column",
				width: "100%",
				gap: "16px",
			}}
		>
			<Divider>
				<Tooltip title={`Tempo de resposta: ${(responseTime / 1000).toFixed(2)}s`}>
					<ClockCircleOutlined
						style={{ color: colorPrimary, paddingRight: "10px" }}
					/>
				</Tooltip>
				{formatDate(new Date(createdAt))}
			</Divider>
			<Space>
            {clientData && Object.keys(clientData).map((item) => <Tag color={'primary'}>{item}: {clientData[item]}</Tag>)}
            </Space>
            <div style={{ width: "fit-content", maxWidth: "80%" }}>
				<Card size={"small"}>
					<Typography.Text
						ellipsis={{
							tooltip: userMessage,
							rows: 2,
						}}
					>
						{userMessage}
					</Typography.Text>
				</Card>
			</div>
			{response?.map((item, i) => (
				<div
					style={{
						width: "fit-content",
						justifySelf: "flex-end",
						maxWidth: "80%",
					}}
				>
					<Card size={"small"} style={{ borderColor: colorPrimary }} key={i}>
						{item.type === "text" && <TextMessage text={item.data} />}
						{item.type === "trigger" && <TriggerMessage data={item.data} />}
						{item.type === "reference" && <ReferenceMessage data={item.data} />}
					</Card>
				</div>
			))}
			<div
				style={{
					width: "fit-content",
					justifySelf: "flex-end",
					maxWidth: "80%",
				}}
			>
                {isAnythingElse && (
					<Tag icon={<WarningOutlined />} color={"warning"}>
						Fora do contexto
					</Tag>
				)}
			</div>
		</div>
	);
}

function TextMessage({ text }) {
	const { Paragraph } = Typography;

	return (
		<Paragraph
			ellipsis={{
				rows: 4,
				expandable: true,
				symbol: "Ler mais",
			}}
		>
			{text}
		</Paragraph>
	);
}

function TriggerMessage({ data }) {
	const parsedData = useMemo(() => JSON.parse(data ?? "{}"), [data]);

	const functionPretty = useMemo(
		() =>
			`${parsedData?.function_name}(${Object.values(parsedData?.args ?? {})?.join(
				", "
			)})`,
		[parsedData]
	);

	return (
		<Popover content={<code>{JSON.stringify(parsedData, null, "\t")}</code>}>
			<Tag icon={<ThunderboltOutlined />} color={"orange"}>
				{functionPretty}
			</Tag>
		</Popover>
	);
}

function ReferenceMessage({ data }) {
	const parsedData = useMemo(() => JSON.parse(data ?? "{}"), [data]);
	return (
		<List
			dataSource={parsedData}
			grid={{ gutter: 5 }}
			renderItem={item => {
				const {text, icon, link} = getSourceData(item)
				const linkIcon = link ? <><Link target="_blank" rel="noopener noreferrer" to={link}><LinkOutlined/></Link></> : '';
				return (
					<List.Item>
						<Space direction={"vertical"}>
							<Popover
								title={<p>{item?.title} {linkIcon}</p>}
								content={
									<Typography.Text>
									 	{text} <br />
										Score: {item?.score?.toFixed(2)}<br />
									</Typography.Text>
								}>
								<Button>{item && icon}</Button>
							</Popover>
						</Space>
					</List.Item>
				)
			}}
		/>
	);
}
