import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactFlow, {
    Background, MiniMap, useOnSelectionChange, useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { newObjectID } from "../../utils";
import Initial from "../../components/initial_node";
import Node from "../../components/node";
import ChatBotDataContext from "../../context/chatbot-data";
import Toolbar from "../../components/toolbar";
import ChatTest from "../../components/chat-test";
import ActionsContext from "../../context/actions";
import JumpEdge from "../../components/custom-edge";
import { Button, Card, message, Result, Row, theme, Typography } from "antd";
import dagre from "dagre";
import FloatingContainer from "../../components/floating-container";
import Triggers from "../../components/triggers";
import ContextMenu from '../../components/context-menu';
import Group from '../../components/group-node'
import MinimizedGroup from '../../components/minimized-group-node'
import FlowContext from "../../context/flow";
import ComponentContainer from '../../components/component-container';
import useNode from '../../hooks/useNode';
import { useNodesContext } from '../../context/nodesProvider';
import { useEdgesContext } from '../../context/edgesProvider';
import useData from '../../hooks/useData';
import useEdge from '../../hooks/useEdge';
import MessageApiContext from "../../context/message-api";
import LastEditions from "../../components/last-editions";
import {
    CloseCircleOutlined, CopyOutlined,
    DownloadOutlined,
    InteractionOutlined,
    LoadingOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import { Err } from "../login/styles";
import ExportJson from "../../components/export-json";
import { usePlatform, useShortcuts } from '../../hooks/useShortcuts';

const nodeTypes = {
    node: Node, group: Group, minimized: MinimizedGroup, initial: Initial,
};

const edgeTypes = {
    jump: JumpEdge,
};

const nodeWidth = 250;
const nodeHeight = 150;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const organizeFlow = (data) => {
    dagreGraph.setGraph({ rankdir: 'TB' });

    for (const nodeId in data.nodes) {
        dagreGraph.setNode(nodeId, { width: nodeWidth, height: nodeHeight });
    }

    for (const source in data.edges) {
        for (const target in data.edges[source]) {
            dagreGraph.setEdge(source, target);
        }
    }

    dagre.layout(dagreGraph);//

    // 1. Encontrar o valor x mais à esquerda
    let minX = Infinity;
    for (const nodeId in data.nodes) {
        const nodeWithPosition = dagreGraph.node(nodeId);
        minX = Math.min(minX, nodeWithPosition.x);
    }

    // 2. Ajustar todas as coordenadas x dos nós
    for (const nodeId in data.nodes) {
        const nodeWithPosition = dagreGraph.node(nodeId);

        // Ajusta x para começar em 0
        let adjustedX = nodeWithPosition.x - minX;

        data.nodes[nodeId].position = {
            x: adjustedX - nodeWidth / 2, y: nodeWithPosition.y - nodeHeight / 2 + 100,
        };
    }

    // 3. Centralizar em x: 0
    let maxX = -Infinity;
    for (const nodeId in data.nodes) {
        maxX = Math.max(maxX, data.nodes[nodeId].position.x + nodeWidth / 2);
    }

    const graphWidth = maxX;
    for (const nodeId in data.nodes) {
        data.nodes[nodeId].position.x -= graphWidth / 2;
    }

    return data;
};

class Editor extends React.Component {
    state = {
        error: false,
        errorMessage: "",
        errorInfo: "",
    }

    constructor(props) {
        super(props);
    }

    static getDerivedStateFromError(error) {
        return { error: true, errorMessage: String(error) };
    }

    componentDidCatch(error, errorInfo) {
        this.state.error = true
        this.state.errorInfo = errorInfo?.componentStack
        console.log({ errorInfo })
    }

    render() {
        if (this.state.error)
            return <ErrorScreen errorMessage={this.state.errorMessage}
                errorInfo={this.state.errorInfo}
            />;

        return <InternalEditor {...this.props} />
    }
}

function ErrorScreen({ errorMessage, errorInfo }) {
    const messageApi = useContext(MessageApiContext);
    const [data] = useContext(ChatBotDataContext);

    const saveError = useCallback(() => {
        navigator.clipboard.writeText(errorInfo).catch((e) => {
            console.log(e)
            messageApi.error('Falha ao copiar erro');
        });
    }, [errorInfo])

    return (
        <div style={{ overflow: 'auto', height: '100%' }}>
            <Row justify={'center'} align={'middle'}>
                <Result
                    status="error"
                    title="Falha interna!"
                    subTitle="Infelizmente ocorreu um erro interno, mas não se preocupe, salvamos o seu progresso!."
                    extra={[
                        <Button type="primary"
                            onClick={() => window.location.reload()}
                            icon={<ReloadOutlined spin={false} />}
                            key="reload">
                            Recarregar
                        </Button>,
                        <ExportJson filename={data.version} data={data}>
                            <Button icon={<DownloadOutlined />} key="buy">
                                Baixe aqui
                            </Button>
                        </ExportJson>,
                    ]}
                >
                    <div className="desc">
                        <Typography.Paragraph>
                            <CloseCircleOutlined />
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 16,
                                    marginLeft: 8,
                                }}
                            >
                                {errorMessage}
                            </Typography.Text>
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{ whiteSpace: 'pre-line' }}
                            ellipsis={{ rows: 6, expandable: true, symbol: "Ver mais" }}>
                            {errorInfo}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            <Button onClick={saveError} style={{ padding: 0 }} icon={<CopyOutlined />} type={'link'}>Copiar
                                erro</Button>
                        </Typography.Paragraph>
                    </div>
                </Result>
            </Row>
        </div>
    )
}

function InternalEditor() {
    const { convertData } = useData();
    const messageApi = useContext(MessageApiContext);

    const [data, setData] = useContext(ChatBotDataContext);
    const [featureOpened, setFeatureOpened] = useContext(ActionsContext)['featureOpened'];
    const [isIframe] = useContext(ActionsContext)['iframe'];
    const update = useContext(ActionsContext)['update'];
    const [isNotSaved] = useContext(ActionsContext)['notSaved'];
    const [focusedNode, setFocusedNode] = useContext(ActionsContext)['focusedNode'];
    const { flow } = useContext(FlowContext);

    const { nodes, setNodes, onNodesChange } = useNodesContext([]);
    const { updateNode, createNode, deleteNodes } = useNode();
    const { dataEdgeToEdge, createEdge, deleteEdge } = useEdge();
    const { edges, setEdges, onEdgesChange } = useEdgesContext([]);
    const { isMac } = usePlatform();
    const { project, setViewport, setCenter } = useReactFlow();

    const [contextMenuPosition, setContextMenuPosition] = useState(null);
    const [currentContextMenu, setCurrentContextMenu] = useState('');
    const [isComponentOpen, setIsComponentOpen] = useState();
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [pastePosition, setPastePosition] = useState({ x: 0, y: 0 });

    const pasteRef = useRef({ x: 0, y: 0 });
    const wrapper = useRef();

    const onChange = useCallback(({ nodes }) => {
        setFocusedNode(nodes.map((node) => node.id));
    }, [setFocusedNode])

    useOnSelectionChange({
        onChange
    });

    useEffect(() => {
        pasteRef.current = pastePosition
    }, [pastePosition])

    const onNodeDragStop = useCallback((event, dt) => {
        if (!dt || !dt.data) return;

        const { data: { id }, position } = dt;

        // const groups = Object.values(data.nodeGroups ?? {})
        // const reactFlowBounds = screenToFlowPosition({
        //     x: event.clientX, y: event.clientY,
        // });

        // groups.forEach((group) => {
        //     if (isInsideGroup(reactFlowBounds, group) && !group.nodes.includes(nodeMoved.id) && !group.minimized) {
        //         let newData = {...data};
        //
        //         let newX = position.x - group.position.x;
        //         let newY = position.y - group.position.y;
        //         finalPosition = {x: newX, y: newY};
        //
        //         let node = {
        //             ...newData.nodes[nodeMoved.id], group: group.id
        //         };
        //
        //         let nodesOfGroup = newData.nodeGroups[group.id].nodes;
        //         nodesOfGroup.push(nodeMoved.id);
        //
        //         updateNode({
        //             ...newData, nodes: {
        //                 ...newData.nodes, [nodeMoved.id]: node
        //             }, nodeGroups: {
        //                 ...newData.nodeGroups, [group.id]: {
        //                     ...newData.nodeGroups[group.id], nodes: nodesOfGroup
        //                 }
        //             }
        //
        //         })
        //     }
        // });

        updateNode(id, { position });
    }, []);

    const onSelectionDragStop = useCallback((_, nodes) =>
        nodes.forEach(node => onNodeDragStop(_, node))
        , [onNodeDragStop]);

    const handlePastePosition = (event) => {
        setPastePosition({ x: event.clientX, y: event.clientY });
    };

    const handleNodesDelete = useCallback((nodes) => {
        const nodesToDelete = []
        const newData = { ...data }
        for (const node of nodes) {
            // if (node.type === 'group') {
            //     delete newData.nodeGroups[node.id];
            //     continue;
            // }
            nodesToDelete.push(node.id);
            delete newData.nodes[node.id];
        }
        deleteNodes(nodesToDelete)
    }, [flow, isIframe])

    useEffect(() => {
        convertData(data, flow, isIframe);
    }, [flow, isIframe, data?.nodeGroups])

    const connectingNodeId = useRef(null);
    const reactFlowWrapper = useRef(null);

    const onPaneContextMenu = useCallback((event) => {
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        setContextMenuPosition({
            x: event.clientX - reactFlowBounds.left, y: event.clientY - reactFlowBounds.top,
        });
    }, [reactFlowWrapper]);

    const onSelectionContextMenu = useCallback((event, nodes) => {
        event.preventDefault();

        setCurrentContextMenu({ type: 'selection', data: nodes });
    }, []);

    const onNodeContextMenu = useCallback((event, node) => {
        event.preventDefault();

        setCurrentContextMenu({ type: 'node', data: node.data });
    }, []);

    const onConnectStart = useCallback((_, data) => {
        connectingNodeId.current = data;
    }, []);

    const closeContextMenu = () => {
        setContextMenuPosition(null);
    };

    const onConnectEnd = useCallback((event) => {
        if (connectingNodeId.current.handleId !== "c") return;
        // if (data?.nodeGroups && data?.nodeGroups[connectingNodeId.current.nodeId]) {
        //     return;
        // }

        const targetIsPane = event.target.classList.contains('react-flow__pane');
        const sourceEdges = data?.edges ? data?.edges[connectingNodeId.current.nodeId] : undefined;
        if (!!Object.values(sourceEdges ?? {}).find(item => item?.type.includes('jump')) && targetIsPane) {
            return messageApi.error('Remova o "pulo" antes de criar um novo nó');
        }

        if (targetIsPane) {
            const newResponse = {
                id: newObjectID(), title: '', type: 'text', data: ''
            };

            const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
            const newNode = {
                id: newObjectID(), title: 'Novo nó', condition: '', position: project({
                    x: event.clientX - left - 87, y: event.clientY - top
                }), responses: [newResponse.id],
            };
            createNode(newNode)

            const newEdge = {
                id: newObjectID(), type: 'flow', source: connectingNodeId.current.nodeId, target: newNode.id
            }

            let newData = { ...data };
            if (!newData.responses) newData.responses = {}

            if (!newData?.nodes) newData.nodes = {};

            if (!newData?.edges) newData.edges = {};

            createNode(newNode)
            newData.responses[newResponse.id] = newResponse;

            createEdge(newEdge)
        }
    }, [project, data]);

    const customNodesChange = useCallback((changes) => {
        for (let i = 0; i < changes.length; i++) {
            const node = changes[i];
            if (node?.type === 'remove') setEdges(old => old.filter(edge => edge.source !== node.id && edge.target !== node.id));

            if (node.type !== 'add' || !node?.item?.data || typeof node?.item?.data !== 'string' || !data?.nodes[node?.item?.data]) continue;

            let toCopy = data.nodes[node.item.data]
            let responses = {};
            let resIds = []
            for (let resToCopy of toCopy.responses) {
                let resData = data.responses[resToCopy];
                const id = newObjectID();
                responses[id] = {
                    ...resData, id,
                }
                resIds.push(id);
            }

            let newNode = {
                ...toCopy, ...node.item.data, id: newObjectID(), responses: resIds
            };

            changes[i].item.data = newNode

            setData(old => ({
                ...old, nodes: { ...old.nodes, [newNode.id]: newNode }, responses: { ...old.responses, ...responses }
            }))
        }
        onNodesChange(changes)
    }, [nodes, data, setData, onNodesChange])

    const onEdgesDelete = useCallback((removedEdges) => {
        deleteEdge(removedEdges)
    }, [data]);

    const onConnect = useCallback((params) => {
        if (params.target === params.source) {
            messageApi.error('Você não pode ligar o nó a ele mesmo');
            return;
        }

        let edge = {
            id: newObjectID(), source: params.source, target: params.target
        };

        if (params?.targetHandle === 'a') {
            edge.type = 'flow';

            setData((old) => {
                if (old?.edges[params?.source]) for (let edge of Object.values(old.edges[params.source])) if (edge.type === 'jump') delete old.edges[edge.source][edge.target];

                old.edges[params.source] = {
                    ...old.edges[params.source], [params.target]: edge
                };
                return { ...old };
            });
        } else if (params.targetHandle === 'b') {
            setData((old) => {
                delete old.edges[params.source];

                edge.type = 'jump';
                old.edges[params.source] = {
                    ...old.edges[params.source], [params.target]: edge
                };
                return { ...old };
            });
        }

        let newEdges = []
        for (let edge of edges) {
            if (edge.source === params.source && edge.target === params.target) continue;
            if (params.targetHandle === 'b') {
                if (edge.source === params.source) continue;
            }

            newEdges.push(edge);
        }

        setEdges([...newEdges, dataEdgeToEdge(edge, data.nodes[edge.source] ? data.nodes[edge.source].skipUserInput || edge.type.substring(0, 4) === 'jump' : false)]);
    }, [data, edges]);

    useEffect(() => {
        const dc = document.querySelector('body');
        let timeout;
        const handle = (e) => {
            if (((isMac && e.metaKey) || (!isMac && e.ctrlKey)) && e.key === 's') {
                if (isNotSaved) {
                    clearTimeout(timeout)
                    timeout = setTimeout(update, 300);
                }
                return e.preventDefault()
            }
        }
        dc.addEventListener('keydown', handle)
        return () => dc.removeEventListener('keydown', handle)
    }, [isNotSaved])

    function groupNodes(focused) {
        const groups = Object.values(data?.nodeGroups ?? {})
        const selectedData = []
        if (focused.length === 0) {
            messageApi.error('Selecione um ou mais nós para agrupar');
            return
        }
        if (data) {
            for (let node of focused) {
                if (groups.filter(group => group.nodes.includes(node)).length > 0) {
                    messageApi.error('Um ou mais nós já pertencem à um grupo');
                    return
                }
                selectedData.push(data.nodes[node])
            }
            let minX = Infinity, minY = Infinity;
            selectedData.forEach(node => {
                minX = Math.min(minX, node.position.x);
                minY = Math.min(minY, node.position.y);
            });
            let maxX = selectedData[0].position.x, maxY = selectedData[0].position.y;
            selectedData.forEach(node => {
                maxX = Math.max(maxX, node.position.x);
                maxY = Math.max(maxY, node.position.y);
            });
            let width = maxX - minX + 220
            let height = maxY - minY + 120
            const newGroup = {
                id: newObjectID(), title: "Novo Grupo", nodes: focused, minimized: false, position: {
                    x: minX, y: minY
                }, size: {
                    width: width && width > 500 ? width : 550, height: height && height > 500 ? height : 550
                }
            }

            let newData = data;
            newData.nodeGroups = { ...newData.nodeGroups, [newGroup.id]: newGroup }
            selectedData.forEach(node => {
                if (newData.nodes[node.id]) {
                    let newX = newData.nodes[node.id].position.x - minX;
                    let newY = newData.nodes[node.id].position.y - minY;

                    newData = {
                        ...newData, nodes: {
                            ...newData.nodes, [node.id]: {
                                ...newData.nodes[node.id], position: {
                                    x: newX + 10, y: newY + 10,
                                }, group: newGroup.id,
                            },
                        },
                    };
                }
            });
            setData(newData);
        }
    }

    const organize = () => {
        convertData(organizeFlow(data), flow, isIframe);
        messageApi.info("Fluxo organizado!");
    };

    useEffect(() => {
        if (!contextMenuPosition) {
            setCurrentContextMenu()
        }
    }, [contextMenuPosition])
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback((event) => {
        event.preventDefault();

        const component = JSON.parse(event.dataTransfer.getData('application/reactflow'));
        if (typeof component === undefined || !component) {
            return;
        }
        const groupId = newObjectID()

        const position = reactFlowInstance.screenToFlowPosition({
            x: event.clientX, y: event.clientY,
        });
        const ids = Object.keys(component.nodes)

        setData(old => ({
            ...old,
            nodes: { ...old.nodes, ...component.nodes },
            edges: { ...old.edges, ...component.edges },
            responses: { ...old.responses, ...component.responses },
            intents: { ...old.intents, ...component.intents },
            entities: { ...old.entities, ...component.entities },
            nodeGroups: {
                ...old.nodeGroups, [groupId]: {
                    id: groupId,
                    title: component.title,
                    nodes: ids,
                    position,
                    componendID: component.componentId,
                    size: {
                        width: component.parentWidth, height: component.parentHeight,
                    },


                }
            }
        }));

    }, [reactFlowInstance],);


    const handleKeyDown = useCallback(async (event) => {
        if (event.key === "Escape") {
            closeContextMenu();
        }
    }, []);

    const handleGroupNodes = useCallback((event) => {
        event.preventDefault();
        groupNodes(focusedNode);
    }, [focusedNode]);

    const handleCopy = useCallback(async (event) => {
        if(event.target.value || focusedNode.length <= 0) {
            return;
        }
        event.preventDefault();
        const copiedEdges = {};
        const copiedResponses = {};
        const dataEdges = Object.values(data.edges);
        for (const source of dataEdges) {
            for (const edge of edges) {
                if (focusedNode.includes(edge.target) && focusedNode.includes(edge.source)) {
                    copiedEdges[edge.source] = { ...copiedEdges[edge.source], [edge.target]: edge };
                }
            }
        }

        const copiedNodes = Object.values(data.nodes).filter(node => focusedNode.includes(node.id));
        copiedNodes.forEach(node => {
            if (node.hasOwnProperty('response') && !node.responses) {
                node.responses = [];
            }
            node.responses.forEach(response => {
                copiedResponses[response] = data.responses[response];
            });
        })
        const copiedData = {
            nodes: copiedNodes.reduce((acc, node) => {
                acc[node.id] = node;
                return acc;
            }, {}), edges: copiedEdges, responses: copiedResponses
        };
        await navigator.clipboard.writeText(JSON.stringify(copiedData));
    }, [focusedNode, data]);

    const handlePaste = useCallback(async (event) => {
        if (focusedNode.length >= 0) {
            try {
                const mousePosition = reactFlowInstance.screenToFlowPosition(pasteRef.current);
                const clipboardText = await navigator.clipboard.readText();
                if (!clipboardText) {
                    messageApi.error('Nada copiado');
                    return;
                }

                let copiedData;
                try {
                    copiedData = JSON.parse(clipboardText);
                } catch (e) {
                    return;
                }

                const { nodes: copiedNodes, edges: copiedEdges, responses: copiedResponses } = copiedData;
                if (copiedNodes.length === 0 || !copiedNodes) {
                    return;
                }

                const relativePosition = copiedNodes[Object.keys(copiedNodes)[0]].position;
                const newNodeIds = {};
                const newNodeMap = {};
                const newResponseMap = {};
                const nodeIdMapping = {};

                let newEdges = {};

                for (const copiedNode in copiedNodes) {
                    const newNodeId = newObjectID();
                    const nodeResponses = [];
                    newNodeIds[copiedNode] = newNodeId;
                    const deltaX = copiedNodes[copiedNode].position.x - relativePosition.x;
                    const deltaY = copiedNodes[copiedNode].position.y - relativePosition.y;
                    for (const response in copiedResponses) {
                        if (copiedNodes[copiedNode].responses.includes(response)) {
                            const responseNewId = newObjectID();
                            newResponseMap[responseNewId] = {
                                ...copiedResponses[response], id: responseNewId, nodeId: newNodeId
                            };
                            nodeResponses.push(responseNewId);
                        }
                    }
                    const newNode = {
                        ...copiedNodes[copiedNode], id: newNodeId, position: {
                            x: deltaX + mousePosition.x, y: deltaY + mousePosition.y
                        }, responses: nodeResponses
                    };
                    newNodeMap[newNodeId] = newNode;
                    nodeIdMapping[copiedNode] = newNodeId;
                }

                for (const source in copiedEdges) {
                    for (const edge in copiedEdges[source]) {
                        const newEdgeId = newObjectID();
                        newEdges = {
                            ...newEdges, [newNodeIds[source]]: {
                                ...newEdges[newNodeIds[source]], [newNodeIds[edge]]: {
                                    ...copiedEdges[source][edge],
                                    target: newNodeIds[edge],
                                    source: newNodeIds[source],
                                    id: newEdgeId,
                                    type: copiedEdges[source][edge].type
                                }
                            }
                        };
                    }
                }


                setData((data) => {
                    const newData = structuredClone(data)

                    newData.nodes = {
                        ...newData.nodes, ...newNodeMap
                    };
                    newData.edges = {
                        ...newData.edges, ...newEdges
                    };
                    newData.responses = {
                        ...newData.responses, ...newResponseMap
                    };

                    convertData(newData, flow, isIframe);
                    return newData;
                });

            } catch (e) {
                console.log(e);
                // messageApi.error('Falha ao colar');
            }
        }
    }, [focusedNode, data, setData, pasteRef, reactFlowInstance, convertData, flow, isIframe, messageApi]);

    useShortcuts([
        { key: 'g', callback: handleGroupNodes },
        { key: 'c', callback: handleCopy },
        { key: 'v', callback: handlePaste }
    ]);

    useEffect(() => {
        if (featureOpened === 'chatTest' && data?.status === "EDITING") {
            setFeatureOpened('')
            messageApi.error('Você deve treinar o bot antes de abrir o teste!')
        }
    }, [featureOpened, data?.status]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [focusedNode, reactFlowInstance]);

    const {
        token: { colorBgContainer, colorBgMask, colorTextPlaceholder },
    } = theme.useToken();

    return (<div className="wrapper"
        ref={wrapper}
        style={{
            background: colorBgContainer,
            height: isIframe ? 'calc(100vh - 65px)' : 'calc(100vh - 66px)',
            top: 0,
            width: '100%',
            maxWidth: '100%',
            display: "flex",
            flexGrow: 1,
        }}>
        <ReactFlow
            ref={reactFlowWrapper}
            nodeDragThreshold={1}
            snapToGrid={true}
            nodeTypes={nodeTypes}
            onMouseMove={handlePastePosition}
            // onlyRenderVisibleElements={true}
            onSelectionContextMenu={onSelectionContextMenu}
            onNodeContextMenu={onNodeContextMenu}
            onContextMenu={onPaneContextMenu}
            fitView={true}
            minZoom={0.1}
            edgeTypes={edgeTypes}
            nodes={nodes}
            edges={edges}
            onClick={closeContextMenu}
            onNodesChange={customNodesChange}
            onEdgesChange={onEdgesChange}
            onEdgesDelete={onEdgesDelete}
            attributionPosition="top-right"
            selectNodesOnDrag={false}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onNodesDelete={handleNodesDelete}
            onConnectEnd={onConnectEnd}
            onInit={setReactFlowInstance}
            onNodeDragStop={onNodeDragStop}
            onSelectionDragStop={onSelectionDragStop}
            onDrop={onDrop}
            onDragOver={onDragOver}
            proOptions={{ hideAttribution: true }}

        >
            <MiniMap pannable={true} zoomable={true} position={'bottom-left'} nodeColor={colorTextPlaceholder}
                maskColor={colorBgMask} style={{ backgroundColor: colorBgContainer }} />
            <Background color={colorTextPlaceholder} />
        </ReactFlow>

        <LastEditions />
        {featureOpened !== "" &&
            <FloatingContainer uid={'chat-trigger'} wrapper={wrapper} onClose={() => setFeatureOpened('')}>
                {featureOpened === 'chatTest' && data?.status !== "EDITING" &&
                    <ChatTest internalVersion={data?.internalVersion} id={data?.id} status={data?.status}
                        chatbot={data?.chatbot} />}
                {featureOpened === 'triggers' && <Triggers />}
            </FloatingContainer>}
        <Toolbar wrapper={reactFlowWrapper} setCenter={setCenter}
            groupNodes={groupNodes}
            setViewport={setViewport} organize={organize}
            setIsComponentOpen={setIsComponentOpen}
            isComponentOpen={isComponentOpen}
        />


        {contextMenuPosition && (<ContextMenu position={contextMenuPosition} setPosition={setContextMenuPosition}
            groupNodes={groupNodes}
            convertData={convertData}
            onClose={() => setCurrentContextMenu({})}
            currentContextMenu={currentContextMenu} messageApi={messageApi} />)}
        <ComponentContainer reactFlowInstance={reactFlowInstance} setIsOpen={setIsComponentOpen}
            isOpen={isComponentOpen} />
    </div>);
}

export default Editor;
