import {
    Avatar,
    Breadcrumb,
    Button,
    Dropdown, Empty,
    Layout,
    Select,
    Space,
    theme,
    Typography
} from "antd";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {HeaderContent} from "./styles";
import UserContext from "../../context/users";
import './styles.css';
import {
    BankOutlined,
    EditOutlined,
    LoadingOutlined,
    LogoutOutlined,
    RobotOutlined,
    SearchOutlined,
    UserOutlined,
    MoonOutlined,
    SunOutlined
} from "@ant-design/icons";
import {globalSearch} from "../../services/dashboard";
import ThemeContext from "../../context/theme";
import MessageApiContext from "../../context/message-api";

const {Header, Content} = Layout;

const replacementBreacumps = {
    companies: "Empresas",
    users: "Usuários",
    chatbots: "Chatbots",
    create: "Criar",
    flow: "Flow",
    pdf: "PDF",
    'api-keys': "Chaves de Acesso",
    versions: "Versões",
    generative: "Generativo",
    plans: "Planos",
    files: "Arquivos",
    integrations: "Integrações",
    triggers: "Gatilhos",
    tasks: "Tarefas",
    historic: "Histórico",
    assistants: "Assistentes",
    knowledge: "Conhecimento",
    myzap: "MyZap",
    zapi: "Z-Api"
}

const replacementRoles = {
    admin: 'Administrador',
    owner: 'Gerente',
    editor: 'Editor'
}

const replacementUrls = {
    chatbot: '/chatbots',
    user: '/users',
    company: '/companies'
}

const replacementUrlsAfter = {
    chatbot: '/versions',
}

export default function AdminLayout({children, withBackground = true, noPadding = false}) {
    const [user] = useContext(UserContext);
    const navigate = useNavigate();
    const messageApi = useContext(MessageApiContext);
    const location = useLocation();
    const [searchResult, setSearchResult] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const ref = useRef();
    const [actualTheme, setActualTheme] = useContext(ThemeContext);


    const search = useCallback((text) => {
        setSearchLoading(true);
        globalSearch(text).then(({data}) => {
            setSearchResult(data);
            setSearchLoading(false);
        }).catch(e => {
            messageApi.error("Falha ao realizar pesquisa, tente novamente.")
        })
    }, [])

    useEffect(() => {
        const dc = document.querySelector('body');
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        const handle = (e) => {
            if (((isMac && e.metaKey) || (!isMac && e.ctrlKey)) && e.key === 'k') {
                ref?.current?.focus();
                return e.preventDefault()
            }
        }
        dc.addEventListener('keydown', handle)
        return () => dc.removeEventListener('keydown', handle)
    }, [ref])

    const {
        token: {colorBgContainer, colorPrimaryBg, colorPrimaryActive, borderRadius, colorTextPlaceholder},
    } = theme.useToken();

    useEffect(() => {
        if (searchValue?.length < 1)
            return;

        let timeout = setTimeout(() => search(searchValue), 200);
        return () => clearTimeout(timeout);
    }, [searchValue])

    const paragraphStyle = {margin: 0, padding: 0};

    return (
        <Layout className="site-layout">
            <Header style={{padding: 0, background: colorBgContainer}}>
                <HeaderContent>

                    <Space direction={'horizontal'} size={12}>
                        <Button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: 'none'
                            }}
                            icon={actualTheme === 'dark' ? <MoonOutlined/> : <SunOutlined/>}
                            type="text" size="large"
                            onClick={() => setActualTheme((old) => (old === 'dark' ? 'light' : 'dark'))}>
                        </Button>
                        <Dropdown
                            trigger={['click']}
                            menu={{
                                items: [
                                    {
                                        key: "editar",
                                        label: (
                                            <Link to={`/users/${user?.id}`}>
                                                <Button size="small" icon={<EditOutlined/>} type="text">
                                                    Editar
                                                </Button>
                                            </Link>
                                        ),
                                    },
                                    {
                                        key: "logout",
                                        label: (
                                            <div onClick={() => {
                                                localStorage.removeItem("user");
                                                localStorage.removeItem("token");
                                                navigate('/login');
                                            }}>
                                                <Button
                                                    style={{backgroundColor: 'transparent'}}
                                                    size="small" icon={<LogoutOutlined/>}
                                                    type="text" danger>
                                                    Sair
                                                </Button>
                                            </div>
                                        ),
                                    },
                                ]
                            }}
                            placement="bottomRight"
                            arrow
                        >
                            {/*<Button type={'text'} size={'large'}  style={{*/}
                            {/*    display: 'flex',*/}
                            {/*    alignItems: 'center',*/}
                            {/*    justifyContent: 'center',*/}
                            {/*    boxShadow: 'none' }}>*/}
                            <Avatar style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                justifyContent: 'center',
                                boxShadow: 'none'
                            }}>{user?.name?.substring(0, 2).toUpperCase()}</Avatar>
                            {/*{!user?.name ? <Skeleton loading={true} round active avatar paragraph={false}/> :*/}
                            {/*    <Card.Meta*/}
                            {/*      avatar={}*/}
                            {/*      title={user?.name}*/}
                            {/*       description={replacementRoles[user?.role] ?? user?.role}/>*/}
                            {/*}*/}
                            {/*</Button>*/}
                        </Dropdown>
                    </Space>
                    {user?.role === 'admin' && <div style={{
                        display: 'flex',
                        alignSelf: 'center',
                        justifySelf: 'flex-start',
                        padding: 0,
                        margin: 0
                    }}>
                        <SearchOutlined style={{color: colorTextPlaceholder}}/>
                        <Select
                            style={{width: 200}}
                            loading={searchLoading}
                            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                    description={'Nada encontrado'}/>}
                            suffixIcon={searchLoading ? <LoadingOutlined/> : <p style={{width: 10}}>⌘+K</p>}
                            placeholder={'Pesquisar'}
                            defaultActiveFirstOption={true}
                            showSearch
                            filterOption={false}
                            bordered={false}
                            value={null}
                            onSelect={(v) => navigate(v)}
                            onSearch={(v) => setSearchValue(v)}
                            ref={ref}
                            options={searchResult.map(({name, type, description, id}) => ({
                                value: replacementUrls[type] + '/' + id + (replacementUrlsAfter[type] ?? ''),
                                label: <Link to={replacementUrls[type] + '/' + id + (replacementUrlsAfter[type] ?? '')}>
                                    <Space direction={'row'}>
                                        {type === 'chatbot' && <RobotOutlined/>}
                                        {type === 'user' && <UserOutlined/>}
                                        {type === 'company' && <BankOutlined/>}
                                        <Typography.Text>{name}</Typography.Text>
                                    </Space>
                                    <br/>

                                    {type === 'chatbot' &&
                                        <Typography.Paragraph type={'secondary'}
                                                              style={paragraphStyle}
                                                              danger={description}>
                                            {(description === 'true') && 'Ativo'}
                                            {(description === 'false') && 'Desativado'}
                                        </Typography.Paragraph>}
                                    {type === 'company' &&
                                        <Typography.Paragraph style={paragraphStyle} type={'secondary'}>
                                            {description} Usuários
                                        </Typography.Paragraph>
                                    }
                                    {type === 'user' &&
                                        <Typography.Paragraph style={paragraphStyle} type={'secondary'}>
                                            {description}
                                        </Typography.Paragraph>
                                    }
                                </Link>
                            }))}
                        />
                    </div>}
                </HeaderContent>
            </Header>
            <Content style={{padding: '0 16px', overflow: 'auto', height: '100vh'}}>
                <BreadcrumbCustom/>
                {/*{location.pathname.split("/")[1] === 'chatbots' && <Alert message={<>*/}
                {/*    Aviso de migração*/}
                {/*    <Link to={'/assistants'}>*/}
                {/*        <Button type={'link'}>Ir para assistentes</Button>*/}
                {/*    </Link>*/}
                {/*</>}*/}
                {/*        type={"warning"}*/}
                {/*        style={{marginBottom: 16}}*/}
                {/*        closable*/}
                {/*        description={<Typography.Text>*/}
                {/*            Estamos migrando os assistentes generativos para outra aba.*/}
                {/*            Seus assistentes generativos foram automaticamente copiados,*/}
                {/*            mas será necessário subir os arquivos de treinamento novamente.</Typography.Text>*/}
                {/*        }/>}*/}
                <div style={{
                    padding: noPadding ? '0' : 24,
                    background: withBackground ? colorBgContainer : 'transparent',
                    borderRadius
                }}>
                    {children}
                </div>
            </Content>
        </Layout>
    )
}

const BreadcrumbCustom = () => {
    const location = useLocation();
    return <Breadcrumb style={{margin: '16px 0'}}>
        {(location?.pathname === '/' ? 'Painel' : location?.pathname?.slice(1))?.split('/').filter(item => item)
            ?.map((item, i) =>
                <Breadcrumb.Item key={i}>
                    <Link to={'/' + location?.pathname?.split('/').filter(item => item).slice(0, i + 1).join('/')}>
                        {replacementBreacumps[item] ?? item}
                    </Link>
                </Breadcrumb.Item>
            )}
    </Breadcrumb>
}
