import { FilterOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { InputSearch } from "../input-search";
import { getCompanies } from "../../services/companies";

export default function CompanyFilters({onFinish, filters}) {
    const [showFilter, setShowFilter] = useState(false);
    const [form] = Form.useForm();

    const onOk = useCallback(() => {
        form.validateFields().then(() => {
            setShowFilter(false);
            form.submit();
        });
    }, [])

    useEffect(() => {
        form.setFieldsValue(filters);
    }, [filters])

    return (
        <>
            <Button icon={<FilterOutlined/>} type="dashed"
                    onClick={() => setShowFilter(old => !old)}/>
            <Modal title="Filtros" open={showFilter} cancelText={'Cancelar'} onCancel={() => setShowFilter(false)}
                   onOk={onOk}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}>
                    <Form.Item name={'companyID'} label={'Empresa'}>
                        <InputSearch getCallback={getCompanies}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}