import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, List, Popconfirm, Tag } from "antd";
import { Routes, Route, Link } from "react-router-dom";
import { deleteZAPIInstance, getZAPIInstances } from "../../services/zAPIInstances";
import { getAssistant } from "../../services/assistant"
import { Header, Title } from "../users/styles";
import { DeleteOutlined } from "@ant-design/icons";
import { formatDateTime, formatPhoneNumber } from "../../utils";
import AdminLayout from "../../components/admin-layout";
import EditZApi from "./Edit";
import MessageApiContext from "../../context/message-api";

export default function ZApi() {
    return (
        <AdminLayout>
            <Routes>
                <Route index path={'/'} element={<ZApiList />} />
                <Route index path={'/:id'} element={<EditZApi />} />
            </Routes>
        </AdminLayout>
    )
}

function ZApiList() {
    const messageApi = useContext(MessageApiContext);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const [instances, setInstances] = useState([]);

    const reload = useCallback(async () => {
        setLoading(true);
        const { data } = await getZAPIInstances();
        setInstances(data);
        setTotal(data.length);
        setLoading(false);
    }, [setInstances, setTotal, setLoading]);

    useEffect(() => {
        reload().catch(() => messageApi.error("Falha ao carregar usuários"))
    }, [page, perPage, messageApi, reload])

    return (
        <>
            <List
                orientation={'left'}
                itemLayout={'horizontal'}
                dataSource={instances}
                loading={loading}
                header={
                    <Header>
                        <Title>Instâncias Z-Api</Title>
                    </Header>
                }
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page,
                    showSizeChanger: true,
                    pageSizeOptions: [25, 50, 100]
                }}
                renderItem={(item) => <InstanceContainer reload={reload} data={item} />}
            />
        </>
    );
}


function InstanceContainer({ data, reload }) {
    const [name, setName] = useState('');
    const [color, setColor] = useState('purple');
    const [status, setStatus] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');

    const getConnectedSince = () => {
        return formatDateTime(data.createdAt);
    }

    const getStatus = useCallback((data) => {
        if (!data.status?.smartphoneConnected) return setStatus("Desconectado");
        return setStatus("Conectado");
    }, []);

    const getPhoneNumber = useCallback((data) => {
        if (!data.device?.phone) return setPhoneNumber("Número não conectado");
        const formatedNumber = formatPhoneNumber(data.device.phone);
        return setPhoneNumber(formatedNumber);
    }, [])

    useEffect(() => {
        if (status === "Conectado") setColor("green");
        if (status === "Desconectado") setColor("red");
    }, [status]);

    useEffect(() => {
        if (!data) return;
        getPhoneNumber(data);
        getStatus(data)
    }, [data, getPhoneNumber, getStatus]);

    useEffect(() => {
        getAssistant(data.assistantID).then(({ data }) => {
            setName(data.name);
        }).catch((e) => console.log(e))
    }, [data.assistantID]);

    return (
        <List.Item
            actions={[
                <Tag color={color}>
                    {status}
                </Tag>,
                <Link to={`${data?.id}`}>
                    <Button type={'text'}>Editar</Button>
                </Link>,
                <Popconfirm
                    title="Você tem certeza?"
                    description="Essa ação vai deletar a instância"
                    onConfirm={async () => {
                        await deleteZAPIInstance(data?.id);
                        await reload().catch(console.error);
                    }}
                    cancelText={'Cancelar'}>
                    <Button danger type={'text'} style={{ marginLeft: '1rem' }} icon={<DeleteOutlined />} />
                </Popconfirm>
            ]}
        >
            <List.Item.Meta
                title={<Link to={`/assistants/${data.assistantID}`}>{name}</Link>}
                description={
                    `${getConnectedSince()} | ${phoneNumber}`
                }
            />
        </List.Item>
    )
}
