import {
	AudioOutlined,
	FileOutlined,
	FilePdfOutlined,
	GlobalOutlined,
	VideoCameraOutlined,
} from "@ant-design/icons";

function formatIdentifier(identifier) {
	const identifierIsVideo = (identifier) => identifier.includes("-");
	if (identifierIsVideo(identifier)) {
		const [start, end] = identifier.split(" - ").map(parseFloat);
		const formatTime = (time) => {
			const minutes = Math.floor(time / 60);
			const seconds = Math.round(time % 60);
			return `${minutes}:${seconds.toString().padStart(2, "0")} min`;
		};
		return `${formatTime(start)} - ${formatTime(end)}`;
	}
	return identifier;
}

function iconTextByExt(title, identifier) {
	const extension = title?.split(".").pop();
	const reference = formatIdentifier(identifier)
	const icons = {
		mp4: <VideoCameraOutlined />,
		mp3: <AudioOutlined />,
		pdf: <FilePdfOutlined />,
	};
	const unknownIcon = <FileOutlined />;
	const icon = icons[extension] || unknownIcon;

	const text = `${
		extension === "pdf"
			? "Página: "
			: extension === "mp4" || extension === "mp3"
			? "Intervalo: "
			: "Formato não identificado "
	} ${reference}`;

	return { icon, text, icons, reference };
}

export function getSourceData({ identifier, title, link }) {
	const { icon, text, icons, reference } = iconTextByExt(title, identifier);
	
	if (identifier?.startsWith("http") || title?.startsWith("https")) {
		return {
			icon: <GlobalOutlined />,
			link: identifier,
			text: "Site externo",
		};
	}

	return {
		icon,
		link: icon === icons.pdf ? "" : link ?? "",
		text,
		title,
		reference,
	};
}
