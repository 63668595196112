import { newObjectID } from "./utils";

// AdminRole describes the verbeux business
export const AdminRole = 'admin';
// Owner describes the client business
export const OwnerRole = 'owner';

// EditorRole is the editor that can access system
export const EditorRole = 'editor';


// Code Operators
export const OrOperator = "OU";
export const AndOperator = "E";
export const EqualOperator = "=";
export const NotEqualOperator = "!=";


// Triggers

export const StartConversation = "START_CONVERSATION"
export const MissingAnythingElse = "MISSING_ANYTHING_ELSE"

// Actions
export const Response = "RESPONSE"
export const JumpTo = "JUMP_TO"
export const SetContext = "SET_CONTEXT"
export const JumpAndProcess = "JUMP_AND_PROCESS"

// Chatbot types

export const FlowChatbot = "FLOW"
export const GenerativeChatbot = "GENERATIVE"


export const Weekdays = ["D", "S", "T", "Q", "Q", "S", "S"];

export const BaseComponent = {
	"id": newObjectID(),
	"name": "Novo componente",
	"description": "Descrição do componente",
	"tags": [
		"regular"
	],
	"variables": [
		{
			"name": "HOST",
			"type": "boolean",
			"description": "Meu componente"
		}
	],
	"data": {
		"status": "DONE",
		"internalVersion": 1,
		"entities": {
		},
		"intents": {
			"63df626f91866e3ab2ba34c5": {
				"id": "63df626f91866e3ab2ba34c5",
				"title": "Saudação",
				"examples": [
					{
						"text": "Oi tudo ok?"
					},
					{
						"text": "Eae macho!"
					}
				]
			},

			"63f64c73598f61a078262826": {
				"id": "63f64c73598f61a078262826",
				"title": "BomDia",
				"examples": [
					{
						"text": "Bom dia meu chapa"
					},
					{
						"text": "Tenha um bom dia"
					}
				]
			},
		},
		"nodes": {
			"64064114ef11e7225d277001": {
				"id": "64064114ef11e7225d277001",
				"title": "Welcome",
				"condition": "63df626f91866e3ab2ba34c5",
				"response": "640641143aedd2ccd4e83f5a",
				"position": {
					"x": 666.8207597433906,
					"y": -70.73028719667629
				}
			},
		},
		"responses": {
			"640641143aedd2ccd4e83f5a": {
				"id": "640641143aedd2ccd4e83f5a",
				"title": "",
				"type": "text",
				"data": "Olá, seja muito bem vindo"
			},
		},
		"edges": {
			"000000000000000000000000": {
				"64064114ef11e7225d277001": {
					"id": "6414f8a4091770c3e1d45559",
					"type": "flow",
					"source": "000000000000000000000000",
					"target": "64064114ef11e7225d277001"
				}
			},
			"64064114ef11e7225d277001": {
				"6406415768d8d91ee838abfe": {
					"id": "64064157313d1b835ece18fd",
					"type": "flow",
					"source": "64064114ef11e7225d277001",
					"target": "6406415768d8d91ee838abfe"
				},
				"640665d992086327c021de1a": {
					"id": "640665d99dcb76c8f155bed2",
					"type": "flow",
					"source": "64064114ef11e7225d277001",
					"target": "640665d992086327c021de1a"
				}
			},


		}
	}
}

export const tasksInformation = [
    {
        type: "MULTIPLE_MESSAGES",
        title: "Envio de Múltiplas Mensagens",
        description: "Essa tarefa enviará múltiplas mensagens ativamente para uma lista de contatos"
    },
]