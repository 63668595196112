import {
    Button,
    Card, Col,
    Form,
    Image, Modal, Row, Select, Switch, Tag,
    Tooltip, Typography, Input, Space, Result, Divider
} from "antd";
import {
    CheckOutlined,
    CloseOutlined, DisconnectOutlined, DownloadOutlined,
    LoadingOutlined, PlayCircleOutlined, ReloadOutlined, SettingOutlined, WarningOutlined,
} from "@ant-design/icons";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    createEvolutionInstance,
    deleteEvolutionInstance,
    getEvolutionInstance,
    getEvolutionById,
    updateEvolutionInstance, getEvolutionTags, testEvolution
} from "../../services/evolution";
import Loading from "../loading";

export default function EvolutionIntegration({icon, label, assistantID, messageApi}) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [configOpen, setConfigOpen] = useState(false);
    const [testOpen, setTestOpen] = useState(false);

    const [form] = Form.useForm();
    const onlyWithoutTag = Form.useWatch('onlyWithoutTag', form);
    const onlyWithTags = Form.useWatch('onlyWithTags', form);

    useEffect(() => {
        setLoading(true)
        getEvolutionInstance({assistantID}).then(({data}) => {
            if (data.length === 0)
                return
            setData(data[0])
        }).catch((error) => {
            console.log(error)
            messageApi.error("Erro ao buscar integração com site.");
        }).finally(() => {
            setLoading(false)
        });
    }, [assistantID, messageApi])

    const install = useCallback(async () => {
        setButtonLoading(true)
        await createEvolutionInstance({
            assistantID: parseInt(assistantID),
            onlyWithoutTag: false,
            active: true,
        }).then(({data}) => {
            setData(data);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao instalar integração com Evolution.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [assistantID, messageApi]);

    const uninstall = useCallback(async () => {
        setButtonLoading(true);
        await deleteEvolutionInstance(data.id).then(() => {
            setData(undefined);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao remover integração com Evolution.");
        }).finally(() => {
            setButtonLoading(false)
        });
    }, [data, messageApi]);

    const update = useCallback(async (newData) => {
        setButtonLoading(true);
        await updateEvolutionInstance(data.id, {...data, ...newData}).then(({data: updatedData}) => {
            setData({...updatedData});
            setConfigOpen(false);
            messageApi.success("Atualizado com sucesso!");
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao atualizar.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [data, messageApi]);

    const save = useCallback(async (values) => {
        await form.validateFields().then(() => {
            update(values);
        }).catch((err) => {
            console.log(err)
        })

    }, [form, update]);

    const handleConfigOpen = () => {
        form.setFieldsValue(data);
        setConfigOpen(true);
    }

    const handleConfigClose = () => {
        form.resetFields()
        setConfigOpen(false)
    }

    return (
        <>
            <Test data={data} messageApi={messageApi} open={testOpen} setTestOpen={setTestOpen} setData={setData}/>
            <Modal title="Configurar Chat Externo"
                   open={configOpen}
                   cancelText={'Cancelar'}
                   confirmLoading={buttonLoading}
                   okText={'Salvar'}
                   onOk={form.submit}
                   onCancel={handleConfigClose}>
                <br/>
                <Row gutter={[24]}>
                    <Col span={24}>
                        <Form layout={'vertical'} form={form} onFinish={save}>
                            <Form.Item label={'Responder apenas sem etiqueta'}
                                       name={'onlyWithoutTag'}
                                       valuePropName={"checked"}
                                       help={'Responder apenas a contatos que não tiverem nenhum etiqueta.'}
                                       initialValue={false}>
                                <Switch disabled={onlyWithTags}/>
                            </Form.Item>

                            <Form.Item label={'Responder com tags especificas'}
                                       name={'onlyWithTags'}
                                       valuePropName={"checked"}
                                       help={'Responder apenas a contatos tiverem etiquetas especificas.'}
                                       initialValue={false}>
                                <Switch disabled={onlyWithoutTag}/>
                            </Form.Item>

                            {!onlyWithoutTag && <Form.Item label={'Tags'}
                                                           name={'tags'}
                                                           help={'Preencha caso deseje que que o bot só responda clientes com determinadas etiquetas.'}>
                                <Select
                                    mode="tags"
                                    style={{width: '100%'}}
                                    placeholder="Digite tags"
                                    tokenSeparators={[',']}
                                    open={false}
                                />
                            </Form.Item>}
                            <Form.Item label={'Leitura automática'}
                                       name={'autoRead'}
                                       valuePropName={"checked"}
                                       help={'Ler todas as mensagens recebidas pelo assistente automaticamente'}
                                       initialValue={false}>
                                <Switch/>
                            </Form.Item>
                            <Form.Item label={'Definir tag quando enviado pelo app'}
                                       name={'setTagFromApp'}
                                       help={'Preencha caso deseje que o bot defina uma etiqueta quando alguem mandar uma mensagem atraves do app do whatsapp.'}>
                                <Input/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Card
                loading={loading}
                title={<Typography.Title level={5} ellipsis={{tooltip: label}}>{icon} {label}</Typography.Title>}
                extra={
                    <Button type={data ? 'default' : 'primary'}
                            loading={loading || buttonLoading}
                            danger={!!data}
                            onClick={() => data ? uninstall() : install()}
                            icon={data ? <CloseOutlined/> : <DownloadOutlined/>}
                    >{loading ? undefined : data ? 'Remover' : 'Instalar'}</Button>
                }
                actions={data ? [
                    <Tooltip title={'Em breve será possível desconectar'}>
                        <DisconnectOutlined/>
                    </Tooltip>,
                    <SettingOutlined onClick={handleConfigOpen}/>,
                    <>

                            <Tooltip title={data?.tested ? 'Ambiente validado'  :'Realizar testes'}>
                                {data?.tested ?
                                    <CheckOutlined/> :
                                    <PlayCircleOutlined onClick={() => setTestOpen(true)}/>

                                }
                            </Tooltip>
                    </>
                ] : undefined}>
                <Configuration setData={setData} data={data} setTestOpen={setTestOpen} messageApi={messageApi}/>
            </Card>
        </>
    )
}

function Configuration({data, messageApi, setData, setTestOpen}) {
    const [checking, setChecking] = useState(false);

    const isConnected = useMemo(() => data?.status?.instance?.state === 'open', [data?.status?.instance?.state]);
    const testRequired = useMemo(() => !data?.tested, [data?.tested]);

    useEffect(() => {
        if (!data?.id || data?.status?.instance?.state === 'open')
            return;

        let time = 3000;
        let limit = 40;
        let i = 0;
        const interval = setInterval(() => {
            setChecking(true)
            getEvolutionById(data.id).then(({data}) => {
                setData(data);
                if (data?.status?.instance?.state === 'open') {
                    clearInterval(interval);
                    setChecking(false);
                }
            }).catch(e => {
                console.log(e);
                messageApi.error("Falha ao checar status");
            })

            i++;
            if (i > limit) clearInterval(interval);
        }, time)

        return () => clearInterval(interval);
    }, [data?.id, data?.status?.state, messageApi]);

    const Disconnected = () =>
        <Row justify={'center'} wrap>
            <Tag color={'error'} icon={<DisconnectOutlined/>} style={{
                justifySelf: 'center',
                margin: 0,
                marginBottom: 12
            }}>{checking ? "Verificando..." : "Desconectado"} {checking && <LoadingOutlined/>}</Tag>
            <Col span={24}/>
            <Image src={data.qrCodeImage} height={150}/>
        </Row>

    const Connected = () =>
        <Typography.Text>
            Evolution configurada e operando normalmente
        </Typography.Text>

    const MustTest = () =>
        <Row justify={'center'}>
            <Button onClick={() => setTestOpen(true)} icon={<PlayCircleOutlined/>}>Realizar Testes</Button>
        </Row>

    if (!data) return <Typography>
        Configure o Whatsapp com sua instancia da Evolution.
    </Typography>

    return (
        <>{isConnected ? testRequired ? <MustTest/> : <Connected/> : <Disconnected/>}</>
    )
}

const errors = {
    'chat_not_found': {
        title: "Chat não encontrado",
        description: "Recomendamos reinstalar a integração para resolver o problema."
    },
    'user_label_check': {
        title: "Etiqueta não encontrada",
        description: "Não identificamos a etiqueta no chat com seu número (você) "
    },
    'tags_not_found': {
        title: "Nenhuma etiqueta encontrada",
        description: "Não existem etiquetas configuradas no seu número, crie as etiquetas."
    },
    'insufficient_tags': {
        title: "Etiquetas insuficientes",
        description: "Encontramos etiquetas mas são insuficientes, crie pelo menos mais 1 etiqueta."
    },
    //success
}

function Test({data, messageApi, open, setTestOpen, setData}) {
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [testData, setTestData] = useState();
    const [testing, setTesting] = useState(false);

    const doTest = useCallback(({tagID}) => {
        if (!data?.id)
            return;

        setTesting(true);
        testEvolution(data.id, tagID)
            .then(({data}) => {
                if (!data.isError) {
                    messageApi.success("Teste realizado com sucesso!")
                    return setTestOpen(false);
                }

                setData(old => ({...old, tested: true}))
                setTestData(data);
            })
            .catch(e => {
                messageApi.error("Falha ao realizar o teste, tente novamente.");
                console.error(e);
            }).finally(() => setTesting(false));
    }, [data]);

    const getTags = useCallback((id) => {
        if (!id)
            return;

        setLoading(true)
        getEvolutionTags(id).then(({data}) => {
            setTags(data.map(item => ({value: item.id, label: item.name})));
        }).catch(e => {
            messageApi.error("Falha ao ler suas etiquetas, tente deletar e recriar a integração.")
            console.error(e)
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => getTags(data?.id), [data?.id])

    const okText = useMemo(() => {
        if (loading)
            return "Carregando";
        if (tags < 2)
            return "Crie as etiquetas";

        return "Testar";
    }, [loading, tags])

    const onOk = useMemo(() => {
        if (loading || testing)
            return;
        if (tags < 2)
            return getTags(data?.id);

        return form.submit;
    }, [getTags, form, data, loading, testing])

    const okButtonProps = useMemo(() => {
        if (loading)
            return {
                disabled: true,
                loading: true
            };
        if (tags < 2)
            return {
                loading
            };

        return {icon: <PlayCircleOutlined/>, loading: loading || testing};
    }, [loading, tags, testing])

    const ResultElement = () => <Result
        status={'warning'}
        title={errors[testData?.state]?.title}
        subTitle={errors[testData?.state]?.description}
    />


    const Element = () => {
        if (loading)
            return <Loading tryAgain={() => getTags(data?.id)} height={'150px'} title={'Carregando etiquetas'} transparent/>;

        if (tags.length < 2)
            return <Typography.Text strong>
                Garanta que você tem mais de duas etiquetas criadas no seu whatsapp.
            </Typography.Text>;

        return <>
            <Typography.Paragraph>
                Realizaremos alguns testes para validar o funcionamento adequado do whatsapp com a
                plataforma.
            </Typography.Paragraph>
            <Typography.Text strong>
                Abra sua whatsapp business e defina uma etiquena na conversa consigo mesmo. Pesquise por
                "você".
            </Typography.Text>
            <br/>
            <br/>
            <Form form={form}
                  onFinish={doTest}
                  layout={'vertical'}>
                <Form.Item name={'tagID'} label={'Etiqueta'} help={'Escolha a etiqueta que você definiu.'}>
                    <Select options={tags} loading={loading}/>
                </Form.Item>
            </Form>
            <br/>
        </>
    }

    return <Modal
        open={open}
        title={"Realizar testes - Whatsapp"}
        cancelText={"Fechar"}
        onOk={onOk}
        onCancel={() => setTestOpen(false)}
        okText={okText}
        okButtonProps={okButtonProps}
    >
        <Element/>
        {!loading && !testing && testData && <>
            <Divider/>
            <ResultElement/>
        </>}
        {/*{*/}
        {/*    loading ? <Loading tryAgain={getTags} height={'150px'} title={'Carregando etiquetas'} transparent/> :*/}
        {/*        tags?.length < 2 ?*/}
        {/*            <Typography.Text strong>*/}
        {/*                Garanta que você tem mais de duas etiquetas criadas no seu whatsapp.*/}
        {/*            </Typography.Text> :*/}
        {/*            <>*/}
        {/*                <Typography.Paragraph>*/}
        {/*                    Realizaremos alguns testes para validar o funcionamento adequado do whatsapp com a*/}
        {/*                    plataforma.*/}
        {/*                </Typography.Paragraph>*/}
        {/*                <Typography.Text strong>*/}
        {/*                    Abra sua whatsapp business e defina uma etiquena na conversa consigo mesmo. Pesquise por*/}
        {/*                    "você".*/}
        {/*                </Typography.Text>*/}
        {/*                <br/>*/}
        {/*                <br/>*/}
        {/*                <Form form={form}*/}
        {/*                      onFinish={doTest}*/}
        {/*                      layout={'vertical'}>*/}
        {/*                    <Form.Item name={'tagID'} label={'Etiqueta'} help={'Escolha a etiqueta que você definiu.'}>*/}
        {/*                        <Select options={tags} loading={loading}/>*/}
        {/*                    </Form.Item>*/}
        {/*                </Form>*/}
        {/*                <br/>*/}
        {/*            </>*/}
        {/*}*/}
    </Modal>

}
