import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
} from "react-router-dom";
import ChatBots from "./pages/chatbots";
import Login from "./pages/login";
import EditorIFrame from "./pages/editor-iframe";
import Companies from "./pages/companies";
import {ConfigProvider, message, theme} from "antd";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import AdminContainer from "./components/layout";
import ApiKeys from "./pages/apikeys";
import InvalidApiKey from "./pages/invalid-apikey";
import ThemeContext from "./context/theme";
import {useEffect, useState} from "react";
import Plans from "./pages/Plans";
import ForgotPassword from "./pages/forgot-password";
import Components from "./pages/components";
import Tracker from '@openreplay/tracker';
import OpenReplayContext from "./context/openreplay";
import Generative from "./pages/Generative";
import MessageApiContext from "./context/message-api";
import { PlatformProvider } from "./hooks/useShortcuts";
import ZApi from "./pages/zapi";
import MyZap from "./pages/myzap";
import { GoogleOAuthProvider } from "@react-oauth/google";

const devMode = process.env.REACT_APP_ENV === 'development';
const googleId = process.env.REACT_APP_GOOGLE_ID

const tracker = new Tracker({
    projectKey: "es9AwBaEcdhhRrK6HjE6",
    __DISABLE_SECURE_MODE: devMode,
});

tracker.start();

function Routes() {
    const [messageApi, contextHolder] = message.useMessage();
    const [actualTheme, setActualTheme] = useState(localStorage.getItem('theme'));
    const {defaultAlgorithm, darkAlgorithm} = theme;

    useEffect(() => {
        localStorage.setItem('theme', actualTheme)
    }, [actualTheme])

    return (
        <OpenReplayContext.Provider value={tracker}>
            <ThemeContext.Provider value={[actualTheme, setActualTheme]}>
                <GoogleOAuthProvider clientId={googleId}>
                    <ConfigProvider
                    theme={{
                        cssVar: true,
                        algorithm: actualTheme === 'dark' ? darkAlgorithm : defaultAlgorithm,
                        token: {
                            colorPrimary: "#601fc2",
                            colorSuccess: "#38c54c",
                            colorWarning: "#f0c808",
                            colorError: "#d83a34",
                            colorInfo: "#1e90ff",
                            colorSider: "#0e0e0e",
                            wireframe: false,
                            borderRadius: 8,
                        },
                    }}
                >
                    <PlatformProvider>
                        <MessageApiContext.Provider value={messageApi}>
                            {contextHolder}
                                <Router>
                                    <Switch>
                                        <Route path={'/login'} element={<Login/>}/>
                                        <Route path={'/forgot/password'} element={<ForgotPassword/>}/>
                                        <Route path={'/iframe/invalid'} element={<InvalidApiKey/>}/>
                                        <Route path={'/iframe/editor/:snapshot/*'} element={<EditorIFrame/>}/>
                                        <Route path={'*'} element={
                                            <AdminContainer>
                                                <Switch>
                                                    <Route path={'/chatbots/*'} element={<ChatBots/>}/>
                                                    <Route path={'/assistants/*'} element={<Generative/>}/>
                                                    <Route path={'/companies/*'} element={<Companies/>}/>
                                                    <Route path={'/components/*'} element={<Components/>}/>
                                                    <Route path={'/users/*'} element={<Users/>}/>
                                                    <Route path={'/api-keys/*'} element={<ApiKeys/>}/>
                                                    <Route path={'/plans/*'} element={<Plans/>}/>
                                                    <Route path={'/zapi/*'} element={<ZApi/>}/>
                                                    <Route path={'/myzap/*'} element={<MyZap/>}/>
                                                    <Route path={'/'} element={<Dashboard/>}/>
                                                </Switch>
                                            </AdminContainer>
                                        }></Route>
                                    </Switch>
                                </Router>
                        </MessageApiContext.Provider>
                    </PlatformProvider>
                    </ConfigProvider>
                </GoogleOAuthProvider>
            </ThemeContext.Provider>
        </OpenReplayContext.Provider>
    )
}

export default Routes;
