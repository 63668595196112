import {
    Button,
    Card,
    Form,
    Input,
    Modal,
    Switch,
    Typography,
} from "antd";
import {
    CloseOutlined,
    DownloadOutlined,
    SettingOutlined,
} from "@ant-design/icons";

import React, {useCallback, useEffect, useState} from "react";
import {
    createAgendorIntegration,
    deleteAgendorIntegration,
    getAgendorIntegration
} from "../../services/agendor-integration";

export function AgendorIntegration({icon, label, assistantID, messageApi}) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [configOpen, setConfigOpen] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        setLoading(true)
        getAgendorIntegration({assistantID}).then(({data}) => {
            if (data.length === 0)
                return
            setData(data[0])
        }).catch((error) => {
            console.log(error)
            messageApi.error("Erro ao buscar integração com agendor.");
        }).finally(() => {
            setLoading(false)
        });
    }, [assistantID, messageApi]);


    const install = useCallback((values) => {
        setButtonLoading(true)
        createAgendorIntegration({
            assistantID: parseInt(assistantID),
            active: true,
            ...values
        }).then(({data}) => {
            setData(data);
            setConfigOpen(false);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao instalar integração com agendor.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [assistantID, messageApi]);

    const uninstall = useCallback(() => {
        setButtonLoading(true);
        deleteAgendorIntegration(data.id).then(() => {
            setData(undefined);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao remover integração com agendor.");
        }).finally(() => {
            setButtonLoading(false)
        });
    }, [data, messageApi]);

    const update = useCallback((newData) => {
        setButtonLoading(true);
        deleteAgendorIntegration(data.id, {...data, ...newData}).then(({data: updatedData}) => {
            setData({...updatedData});
            setConfigOpen(false);
            messageApi.success("Atualizado com sucesso!");
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao atualizar.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [data, messageApi]);

    const finishForm = useCallback((values) => {
        if (!data)
            return install(values);

        update(values)
    }, [data])

    useEffect(() => {
        form.setFieldsValue(data);
    }, [data, form]);


    return (
        <>
            <Modal title="Configurar Agendor"
                   open={configOpen}
                   cancelText={'Cancelar'}
                   confirmLoading={buttonLoading}
                   okText={'Salvar'}
                   onOk={form.submit}
                   onCancel={() => setConfigOpen(false)}>
                <br/>

                <Form layout={'vertical'}
                      form={form}
                      onFinish={finishForm}>
                    <Form.Item label={'Token'}
                               help={<><a
                                   href={"https://ajuda.agendor.com.br/pt-BR/articles/5482997-o-que-e-e-para-que-usamos-o-token-da-conta"}
                                   target={"_blank"}>Clique aqui</a> para encontrar seu token</>}
                               name={'token'}>
                        <Input placeholder={'ID da instancia do agendor'} autocomplete="off"/>
                    </Form.Item>

                    <Form.Item label={'Criar pessoa automaticamente'}
                               name={'autoCreatePeople'}
                               valuePropName={"checked"}
                               help={'Cria a pessoa de forma automática na primeira mensagem'}
                               initialValue={false}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item label={'Criar negócio automaticamente'}
                               name={'autoCreateDeal'}
                               valuePropName={"checked"}
                               help={'Cria o negócio de forma automática na primeira mensagem'}
                               initialValue={false}>
                        <Switch/>
                    </Form.Item>
                </Form>
            </Modal>
            <Card
                loading={loading}
                title={<Typography.Title level={5} ellipsis={{tooltip: label}}>{icon} {label}</Typography.Title>}
                extra={
                    <Button type={data ? 'default' : 'primary'}
                            loading={loading || buttonLoading}
                            danger={!!data}
                            onClick={() => data ? uninstall() : setConfigOpen(true)}
                            icon={data ? <CloseOutlined/> : <DownloadOutlined/>}
                    >{loading ? undefined : data ? 'Remover' : 'Instalar'}</Button>
                }
                actions={data ? [
                    <SettingOutlined onClick={() => setConfigOpen(true)}/>
                ] : undefined}>
                <Typography>
                    Configure o whatsapp com sua instancia do <a target={'_blank'} rel="noreferrer"
                                                                 href={'https://agendor.com.br'}>agendor</a>.
                </Typography>
            </Card>
        </>
    )
}
