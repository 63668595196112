import {Card, Col, Divider, Form, Row, Skeleton, Space, theme, Typography} from "antd";
import {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CheckCircleFilled, ScheduleOutlined} from "@ant-design/icons";
import ScheduledForm from "./ScheduledForm";
import {getMyZapInstance} from "../../../../../services/myZap";
import dayjs from "dayjs";
import {createTask} from "../../../../../services/tasks";
import {getEvolutionInstance} from "../../../../../services/evolution";
import MessageApiContext from "../../../../../context/message-api";

const initCards = [
    {
        key: "CRONJOB",
        title: "Agendada",
        description: "Realize uma tarefa com tempo agendado.",
        icon: <ScheduleOutlined style={{marginRight: 8}}/>
    }
]

export default function CreateTask({assistantId}) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const triggerType = Form.useWatch("triggerType", form);
    const [creating, setCreating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cards] = useState(initCards);
    const [instances, setInstances] = useState([]);
    const messageApi = useContext(MessageApiContext);
    const [instanceOptions, setInstanceOptions] = useState([]);

    const {
        token: {colorPrimary},
    } = theme.useToken();

    const activeStyle = {
        style: {
            borderColor: colorPrimary,
        },
        styles: {
            header: {
                borderColor: colorPrimary
            }
        }
    }

    useEffect(() => {
        setLoading(true);
        let promises = Promise.all([
            getMyZapInstance({assistantID: assistantId}).then(({data}) => {
                if (data.length === 0)
                    return;
                let instance = {type: "MY_ZAP", uuid: data[0].uuid, id: data[0].id}
                setInstances((prev) => [...prev, instance]);
            }),
            getEvolutionInstance({assistantID: assistantId}).then(({data}) => {
                if (data.length === 0)
                    return;
                let instance = {type: "EVOLUTION", uuid: data[0].uuid, id: data[0].id}
                setInstances((prev) => [...prev, instance]);
            })
        ])
        promises.catch((e) => {
            messageApi.error("Não foi possível carregar instâncias")
            console.log(e)
        })
        promises.finally(() => setLoading(false));
    }, [assistantId]);

    const getInstanceOptions = useCallback(() => {
        const initialGroups = {
            // WHATSAPP: [],
            EVOLUTION: [],
            MY_ZAP: []
        };

        const groupedInstances = instances.reduce((acc, instance) => {
            const {type, uuid, id} = instance;
            if (acc[type]) {
                acc[type].push({
                    label: <span>{uuid} - {id}</span>,
                    value: id,
                });
            }
            return acc;
        }, initialGroups);

        const formattedOptions = Object.entries(groupedInstances).map(([type, options]) => ({
            label: <span>{type}</span>,
            title: type,
            options,
        }));

        setInstanceOptions(formattedOptions);
    }, [instances]);

    useEffect(() => {
        getInstanceOptions();
    }, [getInstanceOptions]);

    const getTaskFormData = (formData) => {
        const data = {contacts: formData.contacts, messages: formData.messages}
        return data;
    }

    const getCronTime = (time) => {
        const formatedTime = dayjs(time).format("HH:mm");
        const hourMinute = formatedTime.split(':');
        const cron = `${hourMinute[1]} ${hourMinute[0]} * * *`;
        return cron;
    }

    const getIntegrationType = (id) => {
        const type = instances.find(item => item?.id === id)?.type;
        return type;
    }

    const formatData = (formData) => {
        formData.triggerData = getCronTime(formData.dateSchedule);
        formData.data = getTaskFormData(formData);
        formData.integrationType = getIntegrationType(formData.integrationID);

        return formData;
    }

    const handleCreateTask = useCallback(async (formData) => {
        setCreating(true);
        await createTask(assistantId, formData)
            .then(() => {
                messageApi.success("Tarefa criada!");
                navigate(`/assistants/${assistantId}/tasks`);
            })
            .catch((err) => {
                console.log(err);
                messageApi.error("Não foi possível criar tarefa");
            }).finally(() => setCreating(false));
    }, [assistantId, navigate])

    const onFinish = () => {
        const formData = form.getFieldsValue(true);
        const formatedFormData = formatData(formData);
        handleCreateTask(formatedFormData);
    }

    return (
        <>
            <Typography.Title level={3}>Criar Tarefa</Typography.Title>
            <Divider/>
            <Space style={{width: "100%"}} direction={'vertical'}>
                <Row gutter={[8, 8]}>
                    {loading && new Array(4).fill().map((item, key) => <Col span={12} key={key}>
                        <Card loading={loading} title={<Skeleton active paragraph={false}/>}>
                            <Skeleton active title={false} size={'small'}>
                                <Card.Meta
                                    title
                                    extra
                                    avatar
                                />
                            </Skeleton>
                        </Card>
                    </Col>)}
                    {!loading && cards.map(({title, description, icon, key}) =>
                        <Col span={10} key={key}>
                            <Card size={'small'}
                                  {...(triggerType === key ? activeStyle : {})}
                                  onClick={() => form.setFieldValue("triggerType", key)}
                                  title={<> {icon} {title}</>}
                                  extra={triggerType === key && <CheckCircleFilled style={{color: colorPrimary}}/>}
                                  hoverable>
                                <Typography.Paragraph type={'secondary'}
                                                      ellipsis={{rows: 2}}>
                                    {description}
                                </Typography.Paragraph>
                            </Card>
                        </Col>
                    )}
                </Row>
                <Divider/>
                {!loading && <Form form={form}
                                   layout={'vertical'}
                                   onFinish={onFinish}>
                    <Form.Item name={'triggerType'} initialValue={'CRONJOB'} noStyle/>
                    {triggerType === "CRONJOB" && <ScheduledForm form={form}
                                                                 creating={creating}
                                                                 instances={instanceOptions}/>}
                </Form>}
            </Space>
        </>
    )
}
