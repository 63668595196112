import {
    RobotOutlined,
    HomeOutlined,
    BankOutlined,
    UserOutlined,
    KeyOutlined,
    DollarOutlined,
    ContainerOutlined,
    BulbOutlined, ApartmentOutlined,
    WhatsAppOutlined,
    ApiOutlined
} from '@ant-design/icons';
import {Layout, Menu, theme} from 'antd';
import LogoImg from '../../assets/logo-mini.svg';
import LogoComplete from '../../assets/logo-background.svg';
import {Link, useLocation, useNavigate} from 'react-router-dom'

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Logo, LogoContainer} from "./styles";
import UserContext from "../../context/users";
import {AdminRole, EditorRole, OwnerRole} from "../../constants";
import {refresh} from "../../services/auth";
import Loading from "../loading";
import OpenReplayContext from "../../context/openreplay";

const {Sider} = Layout;

const LeftBar = (role) => {
    let menu = [];
    if ([AdminRole, EditorRole, OwnerRole].includes(role))
        menu.push({key: '/', label: 'Painel', icon: <HomeOutlined/>})
    if ([AdminRole, EditorRole, OwnerRole].includes(role))
        menu.push({
            label: 'Assistentes', icon: <RobotOutlined/>, children: [
                {key: "/chatbots", label: "Fluxo", icon: <ApartmentOutlined/>},
                {key: "/assistants", label: "Generativo", icon: <BulbOutlined/>},
            ]
        })
    if ([AdminRole].includes(role))
        menu.push({key: '/components', label: 'Componentes', icon: <ContainerOutlined/>})
    if ([AdminRole].includes(role))
        menu.push({key: '/companies', label: 'Empresas', icon: <BankOutlined/>})
    if ([AdminRole, OwnerRole].includes(role))
        menu.push({key: '/users', label: 'Usuários', icon: <UserOutlined/>})
    if ([AdminRole, OwnerRole].includes(role))
        menu.push({key: '/api-keys', label: 'ApiKeys', icon: <KeyOutlined/>})
    if ([AdminRole].includes(role))
        menu.push({
            label: 'Planos', icon: <DollarOutlined/>, children: [
                {key: "/plans/chatbot", label: "Planos do Fluxo", icon: <ApartmentOutlined/>},
                {key: "/plans/assistant", label: "Planos do Generativo", icon: <BulbOutlined/>},
            ]
        })
    if([AdminRole].includes(role))
        menu.push({
            label: 'Whatsapp', icon: <WhatsAppOutlined/>, children: [
                {key: "/zapi", label: "Z-Api", icon: <ApiOutlined/>},
                {key: "/myzap", label: "MyZap", icon: <ApiOutlined/>},
            ]
        })

    return menu;
}

export default function AdminContainer({children}) {
    const [collapsed, setCollapsed] = useState(true);
    const location = useLocation();
    const tracker = useContext(OpenReplayContext);
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [isFail, setIsFail] = useState(false);

    const locationSplit = location?.pathname?.split('/');

    const {
        token: {colorSider: bg},
    } = theme.useToken();

    useEffect(() => {
        doRefresh()
    }, [])
    const doRefresh = useCallback(() => {
        setIsFail(false);

        refresh().then(({data}) => {
            localStorage.setItem("token", data?.token);
            localStorage.setItem("user", JSON.stringify(data?.user));

            setUser(data?.user)
            tracker.setUserID(data?.user?.email);
            setIsFail(false);
        }).catch(e => {
            if (e.status === 401) {
                localStorage.removeItem("token")
                localStorage.removeItem("user")
                navigate('/login?redirect=' + location?.pathname);
            } else
                setIsFail(true);

            console.error(e)
        });
    }, [refresh, setIsFail, setUser, location?.pathname])


    return (
        <UserContext.Provider value={[user, setUser]}>
                {Object.keys(user)?.length <= 0 ?
                    <Loading isFail={isFail} tryAgain={doRefresh} title={'Autenticando...'}/> :
                    <Layout style={{height: "100vh", width: '100%', overflow: 'hidden'}}>
                        <Sider style={{background: bg}} value={'/chatbots'} collapsible collapsed={collapsed}
                            onCollapse={(value) => setCollapsed(value)}>
                            <Link to={'/'}>
                                <LogoContainer collapsed={collapsed}>
                                    <Logo collapsed={collapsed} src={collapsed ? LogoImg : LogoComplete}/>
                                </LogoContainer>
                            </Link>
                            <Menu style={{background: bg}} theme="dark"
                                onClick={({key}) => navigate(key)}
                                selectedKeys={"/" + locationSplit[1]}
                                mode="inline"
                                items={LeftBar(user.role)}/>
                        </Sider>
                        {Object.keys(user ?? {}).length > 0 && children}
                    </Layout>}
        </UserContext.Provider>
    );
}
