import { Button, Card, Col, Divider, Form, Modal, Row, Skeleton, Space, theme, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
    CalendarOutlined,
    CheckCircleFilled,
    CloudUploadOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import { getZAPIInstance } from "../../../../../../services/zAPIInstances";
import WebhookForm from "../WebhookForm";
import ZAPIForm from "../ZAPIForm";
import { getMyZapInstance } from "../../../../../../services/myZap";
import MyZapForm from "../MyZapForm";
import { getEvolutionInstance } from "../../../../../../services/evolution";
import EvolutionForm from "../EvolutionForm";
import GoogleCalendarForm from "../GoogleCalendarForm";
import {getCalendarIntegration} from "../../../../../../services/calendar-integration";

const initCards = [
    {
        key: "WEBHOOK",
        title: "Webhook",
        description: "Realize uma requisição http customizada.",
        icon: <CloudUploadOutlined style={{ marginRight: 8 }} />
    }
]

export default function ActionEdit({ trigger, data, clear, addAction, updateAction, deleteAction }) {
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cards, setCards] = useState(initCards);
    const [zApiData, setZApiData] = useState({});
    const [myZapData, setMyZapData] = useState({});
    const [evolutionData, setEvolutionData] = useState({});
    const [form] = Form.useForm();
    const type = Form.useWatch("type", form);

    const {
        token: { colorPrimary },
    } = theme.useToken();

    const activeStyle = {
        style: {
            borderColor: colorPrimary,
        },
        styles: {
            header: {
                borderColor: colorPrimary
            }
        }
    }

    const onCreate = useCallback(async (values) => {
        setSaving(true);
        await addAction(values)
            .then(clear)
            .finally(() => setSaving(false));
    }, []);

    const onDelete = useCallback(async () => {
        setDeleting(true);
        await deleteAction(data?.id)
            .then(() => {
                clear();
                form.resetFields();
            })
            .finally(() => setDeleting(false));
    }, [data]);

    const onUpdate = useCallback(async (values) => {
        setSaving(true);
        await updateAction(values)
            .then(() => {
                clear();
                form.resetFields();
            })
            .finally(() => setSaving(false));
    }, [clear]);

    const getActionFormData = useCallback((values) => {
        const formData = form.getFieldsValue();
        const headers = formData.headers || [];

        const headersObject = {};
        headers.forEach(header => {
            headersObject[header.key] = header.value;
        });

        const actionData = {
            ...values,
            type: "WEBHOOK",
            action: {
                url: formData.url,
                method: formData.method,
                header: headersObject
            },
            id: data?.id
        };

        if (formData?.body) {
            actionData.action.body = JSON.stringify(JSON.parse(formData.body))
        }

        return actionData;
    }, [form, data]);

    const handleWebhook = useCallback(async (values) => {
        const actionData = getActionFormData(values);
        if (data === -1) {
            onCreate(actionData);
            return;
        }
        onUpdate(actionData);
    }, [getActionFormData, onUpdate, onCreate, data]);

    const onFinish = ((values) => {
        if (type === 'WEBHOOK') {
            return handleWebhook(values);
        }

        return data === -1 ? onCreate(values) : (data ? onUpdate(values) : null);
    });

    const footer = () => {
        return [
            <Button onClick={data === -1 ? clear : onDelete} loading={deleting}>
                {data === -1 ? "Cancelar" : "Deletar"}
            </Button>,
            <Button onClick={form.submit} type={'primary'} loading={saving}>
                {data === -1 ? "Criar" : "Salvar"}
            </Button>
        ]
    }

    const cancel = () => {
        clear();
        form.resetFields();
    }

    useEffect(() => {
        if (!data)
            return setOpen(false);

        form.setFieldValue("generativeTriggerID", trigger.id);
        form.setFieldValue("assistantID", trigger.assistantID);

        setOpen(true);
        if (data === -1) {
            form.setFieldValue("type", "WEBHOOK");
            return;
        }

        form.setFieldsValue(data);

    }, [data, form, trigger.assistantID, trigger.id]);

    useEffect(() => {
        setLoading(true);
        let promises = Promise.all([
            getZAPIInstance({ assistantID: trigger.assistantID }).then(({ data }) => {
                setZApiData(data);
                if (data.length > 0) {
                    setCards(old => [...old, {
                        key: "Z_API",
                        title: "Z-API",
                        description: "Utilize funções da integração nativas do z-api",
                        icon: <WhatsAppOutlined style={{ marginRight: 8 }} />
                    }]);
                }
            }),
            getMyZapInstance({ assistantID: trigger.assistantID }).then(({ data }) => {
                setMyZapData(data);
                if (data.length > 0) {
                    setCards(old => [...old, {
                        key: "MY_ZAP",
                        title: "MyZap",
                        description: "Utilize funções da integração do MyZap",
                        icon: <WhatsAppOutlined style={{ marginRight: 8 }} />
                    }])
                }
            }),
            getCalendarIntegration({ assistantID: trigger.assistantID }).then(({data}) => {
                if (data.length > 0) {
                    setCards(old => [...old, {
                        key: "GOOGLE_CALENDAR",
                        title: "Google Calendar",
                        description: "Utilize funções da integração com Google Calendar",
                        icon: <CalendarOutlined style={{ marginRight: 8 }} />
                    }])
                }
            }),
            getEvolutionInstance({assistantID: trigger.assistantID}).then(({ data }) => {
                setEvolutionData(data);
                if(data.length > 0) {
                    setCards(old => [...old, {
                        key: "EVOLUTION",
                        title: "Evolution",
                        description: "Utilize funções da integração do Evolution",
                        icon: <WhatsAppOutlined style={{ marginRight: 8 }} />
                    }])
                }
            })
        ])

        promises.finally(() => setLoading(false));

        return () => setCards(initCards)
    }, [trigger.assistantID]);

    return (
        <Modal
            width={'80%'}
            title="Ação"
            open={open}
            onCancel={cancel}
            footer={footer}>
            <Typography.Text level={5}>
                A ação é disparada no momento em que o gatilho for acionado. Você pode usar os valores dos
                parametros encontrados em qualquer campo usando "$variavel".
            </Typography.Text>
            <Divider />
            <Space style={{ width: "100%" }} direction={'vertical'}>
                <Row gutter={[8, 8]}>
                    {loading && new Array(4).fill().map((item, key) => <Col span={6} key={key}>
                        <Card loading={loading} title={<Skeleton active paragraph={false} />}>
                            <Skeleton active title={false} size={'small'}>
                                <Card.Meta
                                    title
                                    extra
                                    avatar
                                />
                            </Skeleton>
                        </Card>
                    </Col>)}
                    {!loading && cards.map(({ title, description, icon, key }) =>
                        <Col span={6} key={key}>
                            <Card size={'small'}
                                {...(type === key ? activeStyle : {})}
                                onClick={() => form.setFieldValue("type", key)}
                                title={<> {icon} {title}</>}
                                extra={type === key && <CheckCircleFilled style={{ color: colorPrimary }} />}
                                hoverable>
                                <Typography.Paragraph type={'secondary'}
                                    ellipsis={{ rows: 2 }}>
                                    {description}
                                </Typography.Paragraph>
                            </Card>
                        </Col>
                    )}
                </Row>
                <Form form={form}
                    layout={'vertical'}
                    onFinish={onFinish}>
                    <Form.Item name={'type'} initialValue={'WEBHOOK'} noStyle />
                    <Form.Item name={'generativeTriggerID'} noStyle />
                    <Form.Item name={'assistantID'} noStyle />
                    <Form.Item name={'id'} noStyle/>
                    {type === 'Z_API' && <ZAPIForm form={form} extraData={zApiData} />}
                    {type === 'MY_ZAP'&& <MyZapForm form={form} extraData={myZapData}/>}
                    {type === 'EVOLUTION' && <EvolutionForm form={form} extraData={evolutionData} />}
                    {type === 'WEBHOOK' && <WebhookForm form={form} initValues={data} parameters={trigger.args.parameters} />}
                    {type === 'GOOGLE_CALENDAR' && <GoogleCalendarForm form={form} initValues={data} parameters={trigger.args.parameters} />}
                </Form>
            </Space>
        </Modal>
    )
}
