import {
    DownloadOutlined,
    ImportOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    QuestionOutlined,
    UploadOutlined
} from "@ant-design/icons"
import {Button, Form, Input, Mentions, message, Row, Select, Space, TimePicker, Tooltip, Upload} from "antd"
import {convertCsvToArray, newObjectID} from "../../../../../../../utils";
import React from "react";

const actions = [
    {
        label: "Múltiplas mensagens",
        value: "MULTIPLE_MESSAGES",
    }
]

export const SelectInstance = ({instanceOptions}) => {
    return (
        <Form.Item label={"Instância do Whatsapp"} name={'integrationID'}
                   rules={[
                       {
                           required: true,
                           message: 'Deve escolher uma instância',
                       },
                   ]}
        >
            <Select placeholder={"Selecione a instância"} options={instanceOptions}/>
        </Form.Item>
    )
}

const mentionsOptions = [
    {
        value: "name",
        label: "name"
    }
]

export const Message = ({form}) => {
    const messageType = Form.useWatch('type', form);

    return (
        <>
            <Form.Item label={'Tipo de ação'} name={'type'}>
                <Select options={actions} placeholder={"Selecione o tipo de ação"}/>
            </Form.Item>
            {messageType === "MULTIPLE_MESSAGES" && (
                <Form.List name="messages">
                    {(fields, {add, remove}) => (
                        <div style={{overflowY: 'auto'}}>
                            {fields.map(({key, name, ...restField}) => (
                                <div
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 8
                                    }}
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'text']}
                                        rules={[{required: true, message: 'Deve conter uma mensagem'}]}
                                        style={{flex: 1, marginBottom: 0}}
                                    >
                                        <Mentions
                                            options={mentionsOptions}
                                            prefix={'$'}
                                            placeholder="Mensagem"
                                            style={{height: '32px'}}
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                        style={{fontSize: '20px', marginLeft: 8}}
                                    />
                                    <Form.Item name={[name, 'image']} initialValue={''} noStyle/>
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Adicionar mensagem
                                </Button>
                            </Form.Item>
                        </div>
                    )}
                </Form.List>
            )}
        </>
    );
};

export const DateSchedule = () => {
    const format = 'HH:mm';

    return (
        <Form.Item label={'Selecione um horário'} name={'dateSchedule'}
                   rules={[
                       {
                           required: true,
                           message: 'Deve escolher um horário',
                       },
                   ]}
        >
            <TimePicker size={'large'} placeholder="HH:mm" format={format}/>
        </Form.Item>
    )
}

export const Contacts = ({form, messageApi}) => {
    const dataFromCsv = (csvFile) => {
        const csvArray = convertCsvToArray(csvFile);
        const result = [];

        for (let value of csvArray) {
            const [name, to] = value
            result.push({name, to});
        }

        return result;
    }

    const handleImport = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                let result;
                if (file.type === 'text/csv')
                    result = dataFromCsv(event.target.result);
                else
                    result = JSON.parse(event.target.result);

                if (result?.length < 0)
                    return messageApi.error("Não foi identificado nenhum dado, baixe os arquivos modelo");

                for (let value of result)
                    if (!value?.to || !value?.name) {
                        messageApi.warn("Algum nome ou número está vazio.");
                        break
                    }

                messageApi.success('Arquivo carregado com sucesso!');
                form.setFieldValue("contacts", result);
            } catch (err) {
                console.error("Failed to read JSON")
                messageApi.error('Erro ao ler o arquivo JSON');
            }
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        accept: '.json, .csv',
        multiple: true,
        beforeUpload: handleImport
    };

    return (
        <Space direction={'vertical'} style={{width: '100%'}}>
            <Row justify={'space-between'}>
                <div/>
                <Space>
                    <Upload {...uploadProps}>
                        <Tooltip
                            title={'Importar arquivo com contatos'}>
                            <Button
                                color={'primary'}
                                variant={'text'}
                                icon={<UploadOutlined/>}>
                                Importar
                            </Button>
                        </Tooltip>
                    </Upload>
                    <a href={'https://storage.googleapis.com/verbeux-public-images-prod/public/contact-model.csv'}>
                        <Button type={'text'} icon={<DownloadOutlined/>}/>
                    </a>
                </Space>
            </Row>
            <Form.List name="contacts">
                {(fields, {add, remove}) => (
                    <>
                        <div style={{overflowY: 'auto'}}>
                            {fields.map(({key, name, ...restField}) => (
                                <div
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 8,
                                        gap: 12
                                    }}
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        rules={[{required: true, message: 'Deve conter um nome do contato'}]}
                                        style={{flex: 2, marginBottom: 0}}
                                    >
                                        <Input
                                            placeholder="Nome"
                                            style={{height: '32px'}}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'to']}
                                        rules={[{required: true, message: 'Deve conter um número válido'}]}
                                        style={{flex: 1, marginBottom: 0}}
                                    >
                                        <Input
                                            placeholder="Número"
                                            style={{height: '32px'}}
                                        />
                                    </Form.Item>
                                    <Form.Item name={[name, 'tag']} initialValue={''} noStyle/>
                                    <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                        style={{fontSize: '20px', marginLeft: 8}}
                                    />
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Adicionar contato
                                </Button>
                            </Form.Item>
                        </div>
                    </>
                )}
            </Form.List>
        </Space>
    )
}
