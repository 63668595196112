import {Form, Input} from "antd";

export default function SendImage() {
    return (
        <>
            <Form.Item label={'URL'}
                       rules={[{
                           required: true, message: 'URL é obrigatório',
                       }, {
                           pattern: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                           message: 'URL inválido',
                       }]}
                       name={['action', 'data', 'path']}>
                <Input placeholder={'URL'}/>
            </Form.Item>

            <Form.Item label={'Legenda'}
                       name={['action', 'data', 'caption']}>
                <Input placeholder={'Digite uma legenda'}/>
            </Form.Item>
        </>
    )
}
