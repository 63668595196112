import {Form, Mentions, Select} from "antd";
import {useCallback, useMemo, useState} from "react";


export default function List({parameters}) {
    const available = useMemo(() =>
            parameters.properties.map(({name}) => name)
                ?.map(item => ({label: item, value: item})),
        [parameters])

    return (
        <>
            <Form.Item label={'A partir de'}
                       rules={[
                           {
                               required: true,
                               message: "É preciso escolher um parametro do gatilho",
                           }
                       ]}
                       name={['action', 'data', 'from']}>
                <Select placeholder={'Horário'}
                        options={available}/>
            </Form.Item>
        </>
    )
}
