import Fetch from ".";

export const createEvolutionInstance = async (body) => {
    const data = new FormData();
    for (const key in body) {
        if (Array.isArray(body[key])) {
            for (const value of body[key]) {
                data.append(key+"[]", value);
            }
            continue
        }
        data.set(key, body[key]);
    }

    return Fetch(
        '/evolution-instance/',
        {
            method: 'POST',
            body: data,
        },
    )
}


export const resetEvolutionSession = async (sessionID) => {
    return Fetch(
        '/evolution-instance/reset-session/',
        {
            method: 'POST',
            body: JSON.stringify({sessionID}),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const updateEvolutionInstance = (id, body) => {
    delete body['createdAt']
    delete body['updatedAt']
    delete body['deletedAt']
    delete body['id']

    return Fetch(
        '/evolution-instance/' + id,
        {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const deleteEvolutionInstance = (id) => {
    return Fetch(
        '/evolution-instance/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const getEvolutionById = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");

    return Fetch("/evolution-instance/" + id)
}

export const getEvolutionInstance = async ({ assistantID }) => {
    if (!assistantID)
        return Promise.reject("failed to read id");

    return Fetch("/evolution-instance/?assistantID=" + assistantID)
}

export const getEvolutionInstances = async () => {
    return Fetch("/evolution-instance")
}

export const getEvolutionTags = async (id) => {
    return Fetch(`/evolution-instance/${id}/tags`)
}

export const testEvolution = async (id, tagID) => {
    return Fetch(
        `/evolution-instance/${id}/test`,
        {
            method: 'POST',
            body: JSON.stringify({tagID}),
            headers: {
                "Content-Type": "application/json"
            }
        },
    )
}
