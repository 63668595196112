import Fetch from "./index";

export const getCalendarIntegrationById = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");

    return Fetch("/google-calendar-integration/" + id);
}

export const getCalendarIntegration = async ({ assistantID }) => {
    if (!assistantID)
        return Promise.reject("failed to read id");

    return Fetch("/google-calendar-integration/?assistantID=" + assistantID);
}

export const getCalendarIntegrations = async () => {
    return Fetch("/google-calendar-integration");
}

export const createCalendarIntegration = async (body) => {
    return Fetch(
        '/google-calendar-integration/',
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        },
    );
}

export const deleteCalendarIntegration = async (id) => {
    return Fetch(
        '/google-calendar-integration/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    );
}

export const updateCalendarIntegration = async (id, body) => {
    delete body['createdAt'];
    delete body['updatedAt'];
    delete body['deletedAt'];
    delete body['id'];

    const data = new FormData();
    for (const key in body) {
        if (Array.isArray(body[key])) {
            for (const value of body[key]) {
                data.append(key + "[]", value);
            }
            continue;
        }

        data.set(key, body[key]);
    }

    return Fetch(
        '/google-calendar-integration/' + id,
        {
            method: 'PUT',
            body: data,
        },
    );
}
